import CompanyHeader from 'components/CompanyHeader/CompanyHeader';
import { useState, FC, useEffect } from 'react';
import { collapsibleItems } from 'components/MenuItems/menuItems';
import TextPDFFooter from 'components/TextPDFFooter/TextPDFFooter';
import { sideBarItems } from 'components/SidebarItems/sidebarItems';
import { defaultActiveKeys } from 'components/MenuItems/menuItems';
import { useFetchReports } from 'hooks/useFetchReports';
import {
  useForm,
  FormProvider,
  SubmitHandler,
} from 'react-hook-form';
import styles from './reportPage.module.css';
import {
  Layout,
  Menu,
  Collapse,
  Popconfirm,
  Button,
  Modal,
  Switch,
  Tooltip,
} from 'antd';
import { containsTextOnly } from 'utils/formActions/constTextFields';
import { discardForm } from 'utils/formActions/discardForm';
import { FormInputs } from 'components/Fields/FormInputs';
import ChartPDFFooter from 'components/ChartPDFFooter/ChartPDFFooter';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'stores/store';
import PdfHolder from 'components/PdfHolder/PdfHolder';
import { TextPdf } from 'components/TextPDF/TextPDF';
import { updateContext } from './reportContexts';
import { usePDF } from '@react-pdf/renderer';
import {
  handleActiveKeys,
  handleSidebarSelect,
} from 'utils/navigation/navigation';
import { containsChartOnly } from 'utils/formActions/containsChartFields';
import { getTooltipMesage } from 'utils/formActions/getTooltipMessage';

interface Props {
  isCollapsed?: boolean; // This key is only used for testing
}

const defaultTextPDF = TextPdf([]);

export const ReportPage: FC<Props> = ({ isCollapsed }) => {
  // Local Component state for controlling modal and Menus
  const [formData, setFormData] = useState<FormInputs | null>(null);
  const [modelOpen, setModalOpen] = useState(false);
  const [activeKeys, setActiveKeys] = useState<Array<string>>(
    isCollapsed ? [] : defaultActiveKeys,
  );
  const [useCharts, setUseCharts] = useState(false);

  // React hook form hook to update every time a value is selected
  const formControls = useForm<FormInputs>({ mode: 'onChange' });
  const { getValues, formState, reset, handleSubmit } = formControls;

  // react-pdf hook for updating current PDF
  const [pdfInstance, updatePDF] = usePDF({
    document: defaultTextPDF,
  });

  // Destructure useFetchReports
  const { data, isLoading, isFetched } = useFetchReports(formData);

  // Get user from store
  const user = useAppSelector((state) => state.userReducer.user);

  // Makes sure modal only opens when data is returned and is done fetching
  useEffect(() => {
    if (isFetched && data && isFetched) {
      setModalOpen(true);
    }
  }, [isFetched, data]);

  // Redirect to LoginPage if user is not signed in
  if (!user) {
    return <Navigate to="/" />;
  }

  // Returns true or false if the user has selected chart only reports when
  // the text only reports toggle is active and vice versa
  const hasSelectionError =
    (useCharts && containsTextOnly(getValues())) ||
    (!useCharts && containsChartOnly(getValues()));

  const submitTooltip = getTooltipMesage(useCharts);

  // Returns true if there are errors or no options have been selected
  const formButtonsDisabled =
    !Object.values(getValues()).includes(true) ||
    !!Object.values(formState.errors).length;

  // Form actions for discarding and submitting

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    setFormData(null);
    setFormData(data);
  };

  const onDiscard = () => {
    setFormData(null);
    discardForm(reset);
  };

  const { Sider, Content } = Layout;

  return (
    <>
      <CompanyHeader isLoginPage={false} />
      {!!user.username && (
        <>
          <Layout hasSider>
            <Sider
              theme="light"
              className={styles.sider}
              style={{
                height: '100vh',
                position: 'fixed',
              }}
            >
              <h3 className={styles.menuTitle}>Reports</h3>
              <Menu
                data-test-id="reportSidebar"
                onClick={(selectionEvent) =>
                  handleSidebarSelect(
                    selectionEvent,
                    activeKeys,
                    setActiveKeys,
                  )
                }
                items={sideBarItems}
                style={{
                  borderInlineEnd: 'none',
                }} // This only works here not in css
                defaultSelectedKeys={['visitorReports']}
                mode="inline"
              />
            </Sider>
            <Content className={styles.content}>
              <div className={styles.reportContent}>
                <div className={styles.reportHeader}>
                  Report Generator
                </div>
                <div className={styles.report}>
                  {/* Provider to share formControls to fields in the Collapse menu*/}
                  <FormProvider {...formControls}>
                    <Collapse
                      className={styles.collapsibleMenus}
                      collapsible="header"
                      items={collapsibleItems}
                      defaultActiveKey={activeKeys}
                      activeKey={activeKeys}
                      onChange={(newActiveKeys) =>
                        handleActiveKeys(newActiveKeys, setActiveKeys)
                      }
                    />
                  </FormProvider>
                  <div className={styles.reportButtons}>
                    <Popconfirm
                      title="Discard all selected options?"
                      description="This will also delete the current saved report."
                      onConfirm={onDiscard}
                    >
                      <Button
                        type="primary"
                        danger
                        disabled={formButtonsDisabled && !formData}
                      >
                        Discard All
                      </Button>
                    </Popconfirm>
                    <Tooltip title="Applicable reports will be formatted into charts, while excluding text only reports">
                      <p>Toggle detailed reports?</p>
                    </Tooltip>
                    <Switch
                      data-test-id="chartToggle"
                      disabled={!!formData}
                      checkedChildren="Charts only"
                      unCheckedChildren="Text only"
                      checked={useCharts}
                      onChange={() => setUseCharts(!useCharts)}
                    />
                    <Tooltip
                      title={submitTooltip}
                      open={hasSelectionError}
                    >
                      <Button
                        type="primary"
                        data-test-id="submitButton"
                        disabled={
                          formButtonsDisabled ||
                          (isFetched && !!formData) ||
                          hasSelectionError
                        }
                        loading={isLoading}
                        onClick={handleSubmit(onSubmit)}
                      >
                        Generate Report
                      </Button>
                    </Tooltip>
                    <Button
                      type="default"
                      data-test-id="openReportModalButton"
                      disabled={!data || !formData}
                      onClick={() => setModalOpen(true)}
                    >
                      Open Current Report
                    </Button>
                  </div>
                </div>
              </div>
              <Modal
                title={<h2>Report Preview</h2>}
                width={850}
                onCancel={() => setModalOpen(false)}
                open={modelOpen}
                footer={
                  !useCharts ? (
                    <TextPDFFooter
                      data={data}
                      modalOpenFunction={setModalOpen}
                    />
                  ) : (
                    <ChartPDFFooter
                      modalOpenFunction={setModalOpen}
                    />
                  )
                }
              >
                {/*Provider for sending update function to PDFComponent*/}
                <updateContext.Provider value={updatePDF}>
                  <PdfHolder
                    data={data}
                    useCharts={useCharts}
                    pdfInstance={pdfInstance}
                  />
                </updateContext.Provider>
              </Modal>
            </Content>
          </Layout>
        </>
      )}
    </>
  );
};
