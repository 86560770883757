import { ApiCalls } from 'http/types/apiResponseTypes';
import { ApiNameMap } from 'http/types/apiReturnTypes';

type RecordKeys =
  | 'groupType'
  | 'groupName'
  | 'email'
  | 'startDate'
  | 'location'
  | 'ageRange'
  | 'firstVisit'
  | 'discoveryId'
  | 'motivationId'
  | 'similarExperience'
  | 'whichSimilarExperience'
  | 'activityName'
  | 'visitorId'
  | 'score'
  | 'goodValue'
  | 'favouriteActivity'
  | 'leastFavouriteActivity'
  | 'favouriteAspect'
  | 'improvements'
  | 'suggestedNewActivity'
  | 'overallExperienceScore'
  | 'statement';

// Used in TextPDF and mapChartReport to turn FormInput
// Key into appropriate title for generated reports
export const apiNameMap: Partial<ApiNameMap> = {
  leastFavourite: 'Least favourited activities',
  favouriteActivities: 'Most Favourited activities',
  discoveriesSource: 'How visitors discovered Share Village',
  visitorQuotes: 'User quotes about Share Village',
  avgOpinionFirstTime: 'Average score of first time visitors',
  visitorsByLocation: 'Visitors by location',
  visitorTimeframe: 'Number of visitors in time frame',
  favActivitiesByAge: 'Favourite activities by age group',
  profitFromGroups: 'Profit from different group types (in £GBP)',
  returnRateOverYear: 'Return rate over year',
  avgOverall: 'Average experience rating overall',
  favouriteAspect: 'Favourite aspect about activities',
  suggestedActivities: 'Suggested activities from visitors',
  improvementSuggestions: 'Improvement suggestions from visitors',
  activityReturns: 'Total returns from activities (in £GBP)',
  similarExperience: 'Similar Experiences people have been to',
  activitiesPopularity: 'Popularity of activities by booking amount',
  userMotivation: 'Visitor motivation for booking',
  goodValuePercentage: 'Ratio of Activities rated as good value',
};

// Object map used in Records page to transform object
// keys into appropriate column names for antd table
const objectKeyMap = {
  groupType: 'Group type',
  groupName: 'Group name',
  email: 'Email',
  startDate: 'Start date',
  endDate: 'End date',
  location: 'Location',
  ageRange: 'Age range',
  firstVisit: 'First visit',
  discoveryId: 'Discovery source',
  motivationId: 'Motivation Source',
  similarExperience: 'Similar Experience',
  whichSimilarExperience: 'Which Similar Experience',
  activityName: 'Activity name',
  visitorId: 'Visitor',
  score: 'Score',
  goodValue: 'Good value',
  favouriteActivity: 'Favourite activity',
  leastFavouriteActivity: 'Least Favourite Activity',
  favouriteAspect: 'Favourite Aspect',
  improvements: 'Improvements',
  suggestedNewActivity: 'Activity Suggestions',
  overallExperienceScore: 'Overall Experience Score',
  statement: 'Statement',
  notGoodValue: 'Not good value',
};

// Transform Booleans to Yes or No, more
// appealing to the user to view yes or no

export const transformBoolean = (input: Boolean) => {
  if (input) {
    return 'Yes';
  }
  return 'No';
};

// Returns default value of No statement given
// if returned statement was empty
export const transformStatement = (input: string) => {
  if (!!input) {
    return input;
  }

  return 'No statement given';
};

// ==Type guards for object keys and api name object==
export const checkApiKey = (input: string): input is ApiCalls => {
  return Object.keys(apiNameMap).includes(input);
};

export const checkObjectKey = (
  input: string,
): input is RecordKeys => {
  return Object.keys(objectKeyMap).includes(input);
};

// Uses type guards to get corresponding string name for
// Api Object and column headers
export const getObjectKey = (input: string) => {
  if (checkObjectKey(input)) {
    return objectKeyMap[input];
  }
  return input;
};

export const getApiHeader = (input: ApiCalls | string) => {
  if (typeof input === 'string' && !checkApiKey(input)) {
    return input;
  }
  return apiNameMap[input];
};
