import { Button, Input } from 'antd';
import styles from './activity.module.css';
import { useCreateActivity } from 'hooks/adminHooks/useCreateActivity';
import { useState } from 'react';
import {
  requiredRules,
  numberRules,
} from 'utils/formRules/formRules';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

// Interface for created activities
export interface ActivityState {
  capacity: number;
  name: string;
  price: number;
  duration: number;
}

const ActivityCreator = () => {
  const [activity, setActivity] = useState<ActivityState>({
    capacity: 0,
    name: '',
    price: 0,
    duration: 0,
  });

  const { control, handleSubmit, formState, reset } =
    useForm<ActivityState>({
      mode: 'onChange',
    });
  // Dont use mutate for initial data
  const { data, isLoading, isSuccess } = useCreateActivity(activity);

  const onSubmit: SubmitHandler<ActivityState> = (data) => {
    // Figure out something with react hook form to set value as number
    const transformedData = {
      ...data,
      capacity: Number(data.capacity),
      duration: Number(data.duration),
      price: Number(data.price),
    };
    setActivity(transformedData);
  };

  const { errors } = formState;

  // Reset activity creation form if activity creation was successul
  if (data && isSuccess) {
    setActivity({ capacity: 0, name: '', price: 0, duration: 0 });
    reset();
  }

  // Get errors if present
  const activityCreationError = !!Object.values(errors).length;

  return (
    <div className={styles.createActivityContainer}>
      <h3 className={styles.activityTitle}>Create an activity</h3>
      {activityCreationError && (
        <p className={styles.activityError}>
          Please enter valid details for an activity
        </p>
      )}
      {data && (
        <p className={styles.activitySuccess}>
          Activity Successfully created!
        </p>
      )}
      <p>Name</p>
      <Controller
        name="name"
        control={control}
        rules={requiredRules}
        render={({ field }) => (
          <Input placeholder="Activity name" {...field} />
        )}
      />
      <p>Capacity</p>
      <Controller
        name="capacity"
        rules={numberRules}
        control={control}
        render={({ field }) => (
          <Input placeholder="Total participants" {...field} />
        )}
      />
      <p>Price</p>
      <Controller
        name="price"
        rules={numberRules}
        control={control}
        render={({ field }) => (
          <Input placeholder="Activity price" {...field} />
        )}
      />
      <p>Duration (minutes)</p>
      <Controller
        name="duration"
        rules={numberRules}
        control={control}
        render={({ field }) => (
          <Input placeholder="Activity duration" {...field} />
        )}
      />
      <Button
        className={styles.createActivityButton}
        type="primary"
        disabled={activityCreationError || !formState.isValid}
        onClick={handleSubmit(onSubmit)}
        loading={isLoading}
      >
        Create activity
      </Button>
    </div>
  );
};

export default ActivityCreator;
