import { SelectField } from 'components/Fields/SelectField/SelectField';
import DatePickerRange from 'components/Fields/DatePickerField/DatePickerRange';
import styles from '../reportOptions.module.css';
import { dateRules } from 'utils/formRules/formRules';
import { BarChartOutlined } from '@ant-design/icons';

// Component holding all fields for Visitor section
export const VisitorOptions = () => {
  return (
    <div id="visitorReports" className={styles.options}>
      <SelectField
        name="avgOpinionFirstTime"
        fieldQuestion="Average satisfaction rating of first time visitors"
        icon={<BarChartOutlined />}
      />
      <SelectField
        name="discoveriesSource"
        fieldQuestion="The method of how a visitor discovered Share Village"
        icon={<BarChartOutlined />}
      />
      <SelectField
        name="userMotivation"
        fieldQuestion="Motivating factor for visitor booking with Share Village"
        icon={<BarChartOutlined />}
      />
      <SelectField
        name="numberOfVisitors"
        fieldQuestion="Visitor count within a specific timeframe"
        dynamicQuestion={
          <DatePickerRange
            rules={dateRules}
            name="visitorTimeframe"
            fieldQuestion="Select a timeframe"
          />
        }
        dynamicWatchValue="visitorTimeframe"
      />
      <SelectField
        name="toggleVisitorLocation"
        fieldQuestion="Visitor count grouped by location in a timeframe"
        dynamicQuestion={
          <DatePickerRange
            rules={dateRules}
            name="visitorsByLocation"
            fieldQuestion="Select a timeframe"
          />
        }
        dynamicWatchValue="visitorsByLocation"
      />
      <SelectField
        name="similarExperience"
        fieldQuestion="Similar experiences people have done"
      />
      <SelectField
        name="returnRateOverYear"
        fieldQuestion="Return rate of customers that previously booked"
      />
    </div>
  );
};
