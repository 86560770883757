import { DiscoveriesResponse } from 'http/types/Responses/VisitorResponses/visitorResponses';

const getShareDiscover = async (): Promise<DiscoveriesResponse> => {
  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/visitor/mostDiscoveries`,
    { method: 'GET' },
  );

  return data.json();
};

export default getShareDiscover;
