import { GoodValueResponse } from 'http/types/Responses/ExperienceResponses/experienceResponses';

export const getGoodValue = async (): Promise<GoodValueResponse> => {
  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/experience/getGoodValuePercentage`,
    { method: 'GET' },
  );

  return data.json();
};

export default getGoodValue;
