import deleteStaff from 'http/ApiCalls/Staff/deleteStaff';
import { notification } from 'antd';
import { useQuery } from 'react-query';

// React query hook for removing staff member
export const useRemoveStaff = (deletedUser: string) => {
  return useQuery({
    queryKey: ['deleteStaff', deletedUser], // Call every time deletedUSer changes
    queryFn: async () => deleteStaff(deletedUser),
    onError: () => {
      // Display error message
      notification.error({
        message: 'Failed to delete a staff member',
        description: 'An unexpected error occured',
      });
    },
    enabled: !!deletedUser.length, // Call endpoint if deletedUser is not an empty string
    retry: true,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
