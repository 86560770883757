import {
  ActivityRatings,
  Visitors,
  NormalisedExperiences,
} from 'http/types/apiReturnTypes';
import dayjs from 'dayjs';
import {
  transformBoolean,
  transformStatement,
} from 'components/PdfComponent/PdfMapping/types/apiNameMap';
import { notification } from 'antd';
import { displayFormat } from 'components/Fields/FormInputs';

// Interface for possible format of data from react query hook
type PossibleRecords =
  | (
      | Array<NormalisedExperiences>
      | Array<ActivityRatings>
      | Array<Visitors>
    )
  | undefined;

// Type guard for Experiences object
const experiencesGuard = (
  experiences: PossibleRecords,
): experiences is Array<NormalisedExperiences> => {
  return (
    !!experiences &&
    !!experiences.length &&
    experiences.some((entry) =>
      Object.keys(entry).every((key) =>
        [
          'visitorId',
          'goodValue',
          'favouriteActivity',
          'leastFavouriteActivity',
          'favouriteAspect',
          'improvements',
          'suggestedNewActivity',
          'overallExperienceScore',
          'statement',
        ].includes(key),
      ),
    )
  );
};

// Typeguard for visitors
const visitorGuard = (
  experiences: PossibleRecords,
): experiences is Array<Visitors> => {
  return (
    !!experiences &&
    !!experiences.length &&
    experiences.some((entry) =>
      Object.keys(entry).every((key) =>
        [
          'id',
          'groupType',
          'groupName',
          'email',
          'startDate',
          'endDate',
          'location',
          'ageRange',
          'firstVisit',
          'discoveryId',
          'motivationId',
          'similarExperience',
          'whichSimilarExperience',
        ].includes(key),
      ),
    )
  );
};

// Iterates through each column and transforms data to user friendly values
export const transformRecords = (data: PossibleRecords) => {
  try {
    if (experiencesGuard(data)) {
      const mappedExperience = data.map(
        ({
          visitorId,
          goodValue,
          favouriteActivity,
          leastFavouriteActivity,
          favouriteAspect,
          improvements,
          suggestedNewActivity,
          overallExperienceScore,
          statement,
        }) => {
          return {
            visitorId,
            goodValue: transformBoolean(goodValue), // Transform booleans from true/false to Yes/No
            favouriteActivity,
            leastFavouriteActivity,
            favouriteAspect,
            improvements,
            suggestedNewActivity,
            overallExperienceScore,
            statement: transformStatement(statement), // Returns default statement if no statement given
          };
        },
      );

      return mappedExperience;
    }
    if (visitorGuard(data)) {
      const mappedVisitor = data.map(
        ({
          id,
          groupName,
          groupType,
          email,
          startDate,
          endDate,
          location,
          ageRange,
          firstVisit,
          discoveryId,
          motivationId,
          similarExperience,
          whichSimilarExperience,
        }) => {
          return {
            id,
            groupName,
            groupType,
            email,
            startDate: dayjs(startDate).format(displayFormat), // Transform dates to uk format (DD-MM-YYYY)
            endDate: dayjs(endDate).format(displayFormat),
            location,
            ageRange,
            firstVisit: transformBoolean(firstVisit), // Transform booleans from true/false to Yes/No
            discoveryId,
            motivationId,
            similarExperience: transformBoolean(similarExperience), // Transform booleans from true/false to Yes/No
            whichSimilarExperience,
          };
        },
      );

      return mappedVisitor;
    }
  } catch {
    // Render if unexpected value is returned from backend
    // Return original data
    notification.error({
      message:
        'There was an error with transforming data from the service',
    });
    return data;
  }

  return data;
};
