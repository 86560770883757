import { AllVisitorsResponse } from 'http/types/Responses/VisitorResponses/visitorResponses';
import { date } from 'components/Fields/FormInputs';

const getAllVisitors = async (
  timeframe: date,
): Promise<AllVisitorsResponse> => {
  const dateString = `startDate=${timeframe.startDate}&endDate=${timeframe.endDate}`;

  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/visitor/getAllVisitors?${dateString}`,
    { method: 'GET' },
  );

  return data.json();
};

export default getAllVisitors;
