import {
  FormInputs,
  ImprovementBound,
  date,
} from 'components/Fields/FormInputs';

// Field names of chart only fields
const chartOnlyFields = [
  'goodValuePercentage',
  'profitFromGroups',
  'toggleActivityReturns',
  'avgOpinionFirstTime',
  'discoveriesSource',
  'userMotivation',
  'activitiesPopularity',
  'favouriteActivities',
  'leastFavourite',
  'favActivitiesByAge',
];

export const containsChartOnly = (input: Partial<FormInputs>) => {
  // Removing all fields that were not selected
  const entries = Object.entries(input)
    .map(([key, value]) => {
      if (!!value) {
        return [key, value];
      }
      return undefined;
    })
    .filter(
      // Removing undefined from array of entries
      (
        element,
      ): element is (string | true | date | ImprovementBound)[] =>
        !!element,
    );

  // Turn selected fields into an object
  const selectedEntries = Object.fromEntries(entries);

  // Check if text only fields include keys from selected entries object
  const containsFields = Object.keys(selectedEntries).map((entry) =>
    chartOnlyFields.includes(entry),
  );

  return containsFields.includes(true);
};
