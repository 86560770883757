import { getObjectKey } from 'components/PdfComponent/PdfMapping/types/apiNameMap';
import { RecordsValues } from 'hooks/recordshooks/useGetRecords';

const filteredData = ['id', 'activityId'];

interface TableConfig {
  title: string;
  dataIndex: string;
  key: string;
}

export const getColumnConfig = (data: RecordsValues | undefined) => {
  // Return undefined if value is not valid
  if (!data || !!!data.length) {
    return undefined;
  }
  const [firstElement] = data;
  const keys = Object.keys(firstElement);

  // Map through object keys and generate column config with
  // title, data index and key
  const config: Array<TableConfig | undefined> = keys.map((key) => {
    if (!filteredData.includes(key)) {
      return { title: getObjectKey(key), dataIndex: key, key: key };
    }
    return undefined;
  });

  // Filter through config to ensure no undefined values
  const filteredConfig: Array<TableConfig> = config.filter(
    (column): column is TableConfig => !!column?.title,
  );

  return filteredConfig;
};
