import { PossiblePDFData } from './mappedDataTypes';
import {
  AvgActivityScore,
  LeastFavActivity,
  VisitorDiscoveries,
  DatedNumVisitors,
  ActivityFavByAge,
  IncomePerGroup,
  ReturnRate,
  FavouriteActivities,
  AvgOverallExperience,
  FavAspect,
  ActivitySuggestions,
  ImprovementSuggestions,
  ActivityReturns,
  SimilarExperience,
  BookingPopularity,
  Motivation,
  ValueRatio,
  VisitorLocations,
} from 'http/types/apiReturnTypes';

// File containging all type guards used in TextPDF component
// Type guards check if array is not empty and check if the first
// element has certain keys to determine the element's type

export const isAvgOpinion = (
  input: Array<PossiblePDFData>,
): input is Array<AvgActivityScore> => {
  return (
    !!input?.length &&
    'activityName' in input[0] &&
    'averageScore' in input[0]
  );
};

export const isLeastFav = (
  input: Array<PossiblePDFData>,
  reportName: string,
): input is Array<LeastFavActivity> => {
  return (
    !!input?.length &&
    reportName === 'leastFavourite' &&
    'activityName' in input[0] &&
    'totalVotes' in input[0] &&
    'activityId' in input[0]
  );
};

export const isVisitorDiscoveries = (
  input: Array<PossiblePDFData>,
): input is Array<VisitorDiscoveries> => {
  const [firstElement] = input;

  return (
    !!input?.length &&
    'discoveryName' in firstElement &&
    'numberOfVisitors' in firstElement
  );
};

export const isDatedNumVisitors = (
  input: Array<PossiblePDFData>,
): input is Array<DatedNumVisitors> => {
  const [firstElement] = input;

  return (
    !!input?.length &&
    'startDate' in firstElement &&
    'endDate' in firstElement &&
    'numberOfVisitors' in firstElement
  );
};

export const isActivityFavByAge = (
  input: Array<PossiblePDFData>,
): input is Array<ActivityFavByAge> => {
  const [firstElement] = input;

  return (
    !!input?.length &&
    'ageRange' in firstElement &&
    'activityName' in firstElement &&
    'visitorCount' in firstElement
  );
};

export const isIncomePerGroup = (
  input: Array<PossiblePDFData>,
): input is Array<IncomePerGroup> => {
  const [firstElement] = input;

  return (
    !!input?.length &&
    'groupType' in firstElement &&
    'totalIncome' in firstElement
  );
};

export const isReturnRate = (
  input: Array<PossiblePDFData>,
): input is Array<ReturnRate> => {
  const [firstElement] = input;

  return !!input?.length && 'returnRate' in firstElement;
};

export const isFavActivities = (
  input: Array<PossiblePDFData>,
  name: string,
): input is Array<FavouriteActivities> => {
  return !!input?.length && name === 'favouriteActivities';
};

export const isAvgOverall = (
  input: PossiblePDFData,
): input is AvgOverallExperience => {
  return (
    !!input &&
    !Array.isArray(input) &&
    'averageOverallExperienceScore' in input
  );
};

export const isFavAspects = (
  input: Array<PossiblePDFData>,
): input is Array<FavAspect> => {
  const [firstElement] = input;

  return !!input?.length && 'favouriteAspects' in firstElement;
};

export const isActivitySuggestions = (
  input: Array<PossiblePDFData>,
): input is Array<ActivitySuggestions> => {
  const [firstElement] = input;

  return (
    !!input?.length &&
    'suggestedNewActivity' in firstElement &&
    'date' in firstElement
  );
};

export const isImprovementSuggestions = (
  input: Array<PossiblePDFData>,
): input is Array<ImprovementSuggestions> => {
  const [firstElement] = input;

  return (
    !!input?.length &&
    'overallExperienceScore' in firstElement &&
    'improvements' in firstElement
  );
};

export const isActivityReturns = (
  input: Array<PossiblePDFData>,
): input is Array<ActivityReturns> => {
  const [firstElement] = input;

  return (
    !!input?.length &&
    'activityName' in firstElement &&
    'totalReturn' in firstElement
  );
};

export const isSimilarExperience = (
  input: Array<PossiblePDFData>,
): input is Array<SimilarExperience> => {
  const [firstElement] = input;

  return !!input?.length && 'similarExperience' in firstElement;
};

export const isBookingPopularity = (
  input: Array<PossiblePDFData>,
): input is Array<BookingPopularity> => {
  const [firstElement] = input;

  return (
    !!input?.length &&
    'activityName' in firstElement &&
    'count' in firstElement
  );
};

export const isMotivation = (
  input: Array<PossiblePDFData>,
): input is Array<Motivation> => {
  const [firstElement] = input;

  return (
    !!input?.length &&
    'name' in firstElement &&
    'count' in firstElement
  );
};

export const isValueRatio = (
  input: Array<PossiblePDFData>,
): input is Array<ValueRatio> => {
  const [firstElement] = input;

  return (
    !!input?.length &&
    'goodValue' in firstElement &&
    'notGoodValue' in firstElement
  );
};

export const isVisitorLocations = (
  input: Array<PossiblePDFData>,
): input is Array<VisitorLocations> => {
  const [firstElement] = input;

  return (
    !!input?.length &&
    'numberOfVisitors' in firstElement &&
    'location' in firstElement
  );
};
