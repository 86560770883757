type ReportKeys =
  | 'visitorReports'
  | 'experienceReports'
  | 'activityReports'
  | 'financialReports'
  | 'miscReports';

type ReportIndexHolder = {
  [key in ReportKeys]: string;
};

// Hard coded indices for each side bar options
const reportKeyIndices: ReportIndexHolder = {
  visitorReports: '1',
  experienceReports: '2',
  activityReports: '3',
  financialReports: '4',
  miscReports: '5',
};

// Type guard for checking if index is valid
const isReportKey = (input: string): input is ReportKeys => {
  return Object.keys(reportKeyIndices).includes(input);
};

// If index is valid, get corresponging section in object map
export const getReportKeyIndex = (input: string) => {
  if (isReportKey(input)) {
    return reportKeyIndices[input];
  }
};
