import { SelectField } from 'components/Fields/SelectField/SelectField';
import styles from '../reportOptions.module.css';
import DatePickerRange from 'components/Fields/DatePickerField/DatePickerRange';
import { dateRules } from 'utils/formRules/formRules';
import BoundsField from 'components/Fields/BoundsField/BoundsField';
import { PieChartOutlined } from '@ant-design/icons';

// Component holding all fields for Experience section
export const ExperienceOptions = () => {
  return (
    <div id="experienceReports" className={styles.options}>
      <SelectField
        name="goodValuePercentage"
        fieldQuestion="Percentage of visitors rating an activity as good value"
        icon={<PieChartOutlined />}
      />
      {/* <SelectField
        name="visitorQuotes"
        fieldQuestion="Quotes from past visitors (Not prioritised)"
      /> TO BE DONE IN THE FUTURE*/}
      <SelectField
        name="avgOverall"
        fieldQuestion="Average experience rating overall"
      />
      <SelectField
        name="favouriteAspect"
        fieldQuestion="Favourite aspect about activities"
      />
      <SelectField
        name="toggleSuggestedActivities"
        fieldQuestion="Suggested new activities from visitors"
        dynamicQuestion={
          <DatePickerRange
            rules={dateRules}
            name="suggestedActivities"
            fieldQuestion="Select a timeframe"
          />
        }
        dynamicWatchValue="suggestedActivities"
      />
      <SelectField
        name="toggleImprovementSuggestions"
        fieldQuestion="Improvement suggestions from users"
        dynamicQuestion={
          <BoundsField name="improvementSuggestions" />
        }
        dynamicWatchValue="improvementSuggestions"
      />
    </div>
  );
};
