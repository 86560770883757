import styles from './companyheader.module.css';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { clearUser } from 'stores/userSlice';
import { useAppSelector, useAppDispatch } from 'stores/store';

// Flag for rendering in different pages, will only redirect to
// Login page if not already on LoginPage when checking the flag
interface Props {
  isLoginPage: boolean;
}

const CompanyHeader: FC<Props> = ({ isLoginPage }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.userReducer.user);

  // If user is not signed, redirect to Login page
  if (!!!user && !isLoginPage) {
    return <Navigate to="/" replace={true} />;
  }

  // If user is logged in and user is admin, show admin panel link
  const adminDashboardLink =
    user && user.admin ? (
      <Button
        data-test-id="adminLink"
        className={styles.adminButton}
        onClick={() => navigate('/admin')}
        type="link"
      >
        Admin panel
      </Button>
    ) : null;

  return (
    <div className={styles.companyHeaderContainer}>
      <div className={styles.companyHeader}>
        <h1 className={styles.dashboardTitle}>Reports Dashboard</h1>
        {!!user && (
          <div
            data-test-id="userOptions"
            className={styles.userElements}
          >
            <Button
              className={styles.signOutButton}
              onClick={() => {
                dispatch(clearUser());
              }}
            >
              Sign Out
            </Button>
            <p className={styles.currentUser}>{user.username}</p>
            <Button icon={<UserOutlined />} shape="circle" />
          </div>
        )}
      </div>
      <div className={styles.linksHolder}>
        {!!user && !isLoginPage && (
          <div data-test-id="navLinks">
            <Button
              className={styles.adminButton}
              onClick={() => navigate('/reports')}
              type="link"
            >
              Report Generator
            </Button>
            <Button
              className={styles.adminButton}
              onClick={() => navigate('/records')}
              type="link"
            >
              Records panel
            </Button>
            {adminDashboardLink}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyHeader;
