import TextPDFDownloadLink from 'components/TextPdfDownloadLink/TextPDFDownloadLink';
import { Button } from 'antd';
import styles from './footer.module.css';
import { AllReturnedReports } from 'http/types/apiReturnTypes';
import { FC } from 'react';

interface Props {
  data: AllReturnedReports | undefined;
  modalOpenFunction: (setModalOpen: boolean) => void;
}

// Custom modal footer for antd for TextPDFs, showing download link and cancel
const TextPDFFooter: FC<Props> = ({ data, modalOpenFunction }) => {
  return (
    <div data-test-id="textPDFFooter" className={styles.footer}>
      {/* Call function to close modal if clicked*/}
      <Button onClick={() => modalOpenFunction(false)}>Cancel</Button>
      <TextPDFDownloadLink data={data} />
    </div>
  );
};

export default TextPDFFooter;
