import { Login } from 'pages/LoginPage/LoginPage';
import { User } from 'hooks/useValidateUser';
import { notification } from 'antd';

const authenticateStaff = async (
  userCredentials: Login,
): Promise<User | null> => {
  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/staff/authStaffPassword`,
    {
      method: 'Post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userCredentials),
    },
  );
  if (data.status === 404) {
    notification.error({
      message: 'Could not establish connection to service',
      description:
        'Something went wrong when attempting authentication',
    });
  }

  const outcome = await data.json();

  return outcome;
};

export default authenticateStaff;
