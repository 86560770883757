import { UseFormResetField } from 'react-hook-form';
import { FormInputs } from 'components/Fields/FormInputs';

// Contains all reset functions for all sections in Report Generator
// the resetField function from react hook form is called for each
// field belonging to a section
export const resetVisitorSection = (
  resetField: UseFormResetField<FormInputs>,
) => {
  resetField('avgOpinionFirstTime');
  resetField('numberOfVisitors');
  resetField('visitorTimeframe');
  resetField('visitorsByLocation');
  resetField('discoveriesSource');
  resetField('userMotivation');
  resetField('similarExperience');
  resetField('returnRateOverYear');
};

export const resetExperienceSection = (
  resetField: UseFormResetField<FormInputs>,
) => {
  resetField('goodValuePercentage');
  //resetField('visitorQuotes'); not done
  resetField('avgOverall');
  resetField('favouriteAspect');
  resetField('toggleSuggestedActivities');
  resetField('suggestedActivities');
  resetField('toggleImprovementSuggestions');
  resetField('improvementSuggestions');
};

export const resetActivitiesSection = (
  resetField: UseFormResetField<FormInputs>,
) => {
  resetField('activitiesPopularity');
  resetField('leastFavourite');
  resetField('favouriteActivities');
  resetField('favActivitiesByAge');
};

export const resetFinancialSection = (
  resetField: UseFormResetField<FormInputs>,
) => {
  resetField('profitFromGroups');
  resetField('toggleActivityReturns');
  resetField('activityReturns');
};
