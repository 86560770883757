import { getApiHeader } from './types/apiNameMap';
import { ManualReturnedApiValues } from './types/mappedDataTypes';
import LegendBarChart from 'components/Charts/LegendBarChart/LegendBarChart';
import LabeledBarChart from 'components/Charts/LabeledBarChart/LabeledBarChart';
import LegendPieChart from 'components/Charts/PieChart/LabeledPieChart';
import styles from './mapped.module.css';

// Conditionally renders report sections if they are present in
// input, renders report section header and chart

export const mapChartReport = (
  returnedApiValues: ManualReturnedApiValues,
) => {
  try {
    // Destructure values from input
    const {
      leastFavourite,
      discoveriesSource,
      avgOpinionFirstTime,
      favActivitiesByAge,
      profitFromGroups,
      favouriteActivities,
      activityReturns,
      userMotivation,
      activitiesPopularity,
      goodValuePercentage,
    } = returnedApiValues;

    return (
      <div>
        {!!discoveriesSource?.length && (
          <div>
            <h2 className={styles.reportHeader}>
              {getApiHeader('discoveriesSource')}
            </h2>
            <LegendBarChart
              name="discoveriesSource"
              headers={discoveriesSource.flatMap(
                ({ discoveryName }) => [discoveryName],
              )}
              data={discoveriesSource.flatMap(
                ({ numberOfVisitors }) => [numberOfVisitors],
              )}
              ylabel="Number of visitors"
              title="Discoveries Source"
            />
          </div>
        )}
        {!!leastFavourite?.length && (
          <div>
            <h2 className={styles.reportHeader}>
              {getApiHeader('leastFavourite')}
            </h2>
            <div>
              <LegendBarChart
                name="leastFavourite"
                headers={leastFavourite.flatMap(
                  ({ activityName }) => [activityName],
                )}
                data={leastFavourite.flatMap(({ totalVotes }) => [
                  totalVotes,
                ])}
                title="Activities"
                ylabel="Total Votes"
              />
            </div>
          </div>
        )}
        {!!favouriteActivities?.length && (
          <div>
            <h2 className={styles.reportHeader}>
              {getApiHeader('favouriteActivities')}
            </h2>
            <LegendBarChart
              name="favouriteActivities"
              headers={favouriteActivities.flatMap(
                ({ activityName }) => [activityName],
              )}
              data={favouriteActivities.flatMap(({ totalVotes }) => [
                totalVotes,
              ])}
              title="Activities"
              ylabel="Total votes"
            />
          </div>
        )}
        {!!favActivitiesByAge?.length && (
          <div>
            <h2 className={styles.reportHeader}>
              {getApiHeader('favActivitiesByAge')}
            </h2>
            <LabeledBarChart
              name="favActivitiesByAge"
              headers={favActivitiesByAge.flatMap(
                ({ activityName, ageRange }) => [
                  `${activityName} \n(${ageRange.split(' ')[0]})`,
                ],
              )}
              data={favActivitiesByAge.flatMap(({ visitorCount }) => [
                visitorCount,
              ])}
            />
          </div>
        )}
        {!!profitFromGroups?.length && (
          <div>
            <h2 className={styles.reportHeader}>
              {getApiHeader('profitFromGroups')}
            </h2>
            <LegendPieChart
              name="profitFromGroups"
              data={profitFromGroups.flatMap(
                ({ groupType, totalIncome }, index) => {
                  return {
                    id: index,
                    value: totalIncome,
                    label: groupType,
                  };
                },
              )}
            />
          </div>
        )}
        {!!activityReturns?.length && (
          <div>
            <h2 className={styles.reportHeader}>
              {getApiHeader('activityReturns')}
            </h2>
            <LegendBarChart
              name="activityReturns"
              headers={activityReturns.flatMap(({ activityName }) => [
                activityName,
              ])}
              data={activityReturns.flatMap(({ totalReturn }) => [
                Number(totalReturn),
              ])}
              title="Activities"
              ylabel="Profit"
            />
          </div>
        )}
        {!!goodValuePercentage?.length && (
          <div>
            <h2 className={styles.reportHeader}>
              {getApiHeader('goodValuePercentage')}
            </h2>
            <LegendPieChart
              name="goodValuePercentage"
              data={goodValuePercentage.flatMap((entry) => {
                return [
                  {
                    id: 1,
                    value: entry.goodValue,
                    label: 'Good value',
                  },
                  {
                    id: 2,
                    value: entry.notGoodValue,
                    label: 'Not Good value',
                  },
                ];
              })}
            />
          </div>
        )}
        {!!userMotivation?.length && (
          <div>
            <h2 className={styles.reportHeader}>
              {getApiHeader('userMotivation')}
            </h2>
            <LegendBarChart
              name="userMotivation"
              title="motivations"
              headers={userMotivation.flatMap(({ name }) => [name])}
              data={userMotivation.flatMap(({ count }) => [count])}
              ylabel="count"
            />
          </div>
        )}
        {!!activitiesPopularity?.length && (
          <div>
            <h2 className={styles.reportHeader}>
              {getApiHeader('activitiesPopularity')}
            </h2>
            <LegendBarChart
              name="activitiesPopularity"
              title="Activities"
              headers={activitiesPopularity.flatMap(
                ({ activityName }) => [activityName],
              )}
              data={activitiesPopularity.flatMap(({ count }) => [
                count,
              ])}
              ylabel="Booking count"
            />
          </div>
        )}
        {!!avgOpinionFirstTime?.length && (
          <div>
            <h2 className={styles.reportHeader}>
              {getApiHeader('avgOpinionFirstTime')}
            </h2>
            <LegendBarChart
              name="avgOpinionFirstTime"
              headers={avgOpinionFirstTime.flatMap(
                ({ activityName }) => [activityName],
              )}
              data={avgOpinionFirstTime.flatMap(
                ({ averageScore }) => [
                  Number(averageScore.toFixed(2)),
                ],
              )}
              title="Activities"
              ylabel="Average Score"
            />
          </div>
        )}
      </div>
    );
  } catch {
    return <p>There was an error with rendering data into charts</p>;
  }
};
