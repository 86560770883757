import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from 'hooks/useValidateUser';

export interface Default {
  user: User | null;
}

const defaultUser: Default = {
  user: null,
};

// Store for holding retrieved user and sharing
// amongst components in app
export const userSlice = createSlice({
  name: 'user',
  initialState: defaultUser,
  reducers: {
    // Set retrieved user from endpoint
    setUser: (state, payload: PayloadAction<User>) => {
      state.user = payload.payload;
    },
    // Reset user when loggin out
    clearUser: (state) => {
      state.user = null;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
