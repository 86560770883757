import { ReturnRateResponse } from 'http/types/Responses/MiscResponses/miscResponses';

const getReturnRate = async (): Promise<ReturnRateResponse> => {
  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/visitor/returnRate`,
    { method: 'GET' },
  );

  return data.json();
};

export default getReturnRate;
