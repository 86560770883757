import { Page, Document, Text, View } from '@react-pdf/renderer';
import {
  isActivityFavByAge,
  isActivitySuggestions,
  isAvgOpinion,
  isAvgOverall,
  isDatedNumVisitors,
  isFavActivities,
  isFavAspects,
  isIncomePerGroup,
  isLeastFav,
  isReturnRate,
  isVisitorDiscoveries,
  isImprovementSuggestions,
  isActivityReturns,
  isSimilarExperience,
  isBookingPopularity,
  isMotivation,
  isValueRatio,
  isVisitorLocations,
} from '../PdfComponent/PdfMapping/types/typeGuards';
import { transformNumber, styles } from './textPDFUtils';
import { getApiHeader } from 'components/PdfComponent/PdfMapping/types/apiNameMap';
import { AllReturnedReports } from 'http/types/apiReturnTypes';

// Dynamically renders PDF section based on input
// Uses if statements to check if report is present or not
// and passes current report to TypeGuards. This was done because
// this react package will not render possibly undefined input
export const TextPdf = (formattedReports: AllReturnedReports) => {
  return (
    <Document>
      <Page size={'A4'}>
        <View style={styles.body}>
          <Text style={styles.pageTitle}>Share Village Report</Text>
          {formattedReports.map((report) => {
            // Destructure values from current report
            const [entryName] = Object.keys(report);
            const [entry] = Object.values(report);

            if (Array.isArray(entry) && !entry.length) {
              return (
                <View>
                  <Text style={styles.reportTitle}>
                    {getApiHeader(entryName)}
                  </Text>
                  <Text>No data found</Text>
                </View>
              );
            }

            if (
              Array.isArray(entry) &&
              isLeastFav(entry, entryName)
            ) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('leastFavourite')}
                  </Text>
                  <View style={styles.reportHeaderGroup}>
                    <Text style={styles.reportHeaders}>
                      Activity name
                    </Text>
                    <Text style={styles.reportHeaders}>
                      Total votes
                    </Text>
                  </View>
                  {entry.map(({ activityName, totalVotes }) => {
                    return (
                      <View
                        style={styles.reportEntries}
                        key={`reportEntry-${activityName}`}
                      >
                        <Text style={styles.reportKey}>
                          {`${activityName}: `}
                        </Text>
                        <Text style={styles.reportValue}>
                          {transformNumber(totalVotes)}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              );
            }

            if (Array.isArray(entry) && isVisitorDiscoveries(entry)) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('discoveriesSource')}
                  </Text>
                  <View style={styles.reportHeaderGroup}>
                    <Text style={styles.reportHeaders}>
                      Discovery method
                    </Text>
                    <Text style={styles.reportHeaders}>
                      Number of visitors
                    </Text>
                  </View>
                  {entry.map(
                    ({ discoveryName, numberOfVisitors }) => {
                      return (
                        <View
                          style={styles.reportEntries}
                          key={`reportEntry-${discoveryName}`}
                        >
                          <Text style={styles.reportKey}>
                            {`${discoveryName}: `}
                          </Text>
                          <Text style={styles.reportValue}>
                            {transformNumber(numberOfVisitors)}
                          </Text>
                        </View>
                      );
                    },
                  )}
                </View>
              );
            }

            if (Array.isArray(entry) && isAvgOpinion(entry)) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('avgOpinionFirstTime')}
                  </Text>
                  <View style={styles.reportHeaderGroup}>
                    <Text style={styles.reportHeaders}>
                      Activity name
                    </Text>
                    <Text style={styles.reportHeaders}>
                      Average score
                    </Text>
                  </View>
                  {entry.map(({ activityName, averageScore }) => {
                    return (
                      <View
                        style={styles.reportEntries}
                        key={`reportEntry-${activityName}`}
                      >
                        <Text style={styles.reportKey}>
                          {`${activityName}: `}
                        </Text>
                        <Text style={styles.reportValue}>
                          {transformNumber(averageScore)}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              );
            }

            if (Array.isArray(entry) && isDatedNumVisitors(entry)) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('visitorTimeframe')}
                  </Text>
                  {entry.map(
                    ({ startDate, endDate, numberOfVisitors }) => {
                      return (
                        <View
                          style={styles.reportEntries}
                          key={`reportEntry-${startDate}`}
                        >
                          <Text style={styles.reportKey}>
                            {`${startDate}-${endDate}: `}
                          </Text>
                          <Text style={styles.reportValue}>
                            {transformNumber(numberOfVisitors)}
                          </Text>
                        </View>
                      );
                    },
                  )}
                </View>
              );
            }

            if (Array.isArray(entry) && isActivityFavByAge(entry)) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('favActivitiesByAge')}
                  </Text>
                  <View style={styles.reportHeaderGroup}>
                    <Text style={styles.middleHeaders}>
                      Age range
                    </Text>
                    <Text style={styles.middleHeaders}>
                      Activity Name
                    </Text>
                    <Text style={styles.middleHeaders}>
                      Visitor count
                    </Text>
                  </View>
                  {entry.map(
                    ({ activityName, ageRange, visitorCount }) => {
                      return (
                        <View
                          style={styles.reportEntries}
                          key={`reportEntry-${activityName}`}
                        >
                          <Text style={styles.middleText}>
                            {`${ageRange}:`}
                          </Text>
                          <Text style={styles.middleText}>
                            {activityName}
                          </Text>
                          <Text style={styles.middleText}>
                            {transformNumber(visitorCount)}
                          </Text>
                        </View>
                      );
                    },
                  )}
                </View>
              );
            }

            if (Array.isArray(entry) && isIncomePerGroup(entry)) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('profitFromGroups')}
                  </Text>
                  <View style={styles.reportHeaderGroup}>
                    <Text style={styles.reportHeaders}>
                      Group name
                    </Text>
                    <Text style={styles.reportHeaders}>Profit</Text>
                  </View>
                  {entry.map(({ groupType, totalIncome }) => {
                    return (
                      <View
                        style={styles.reportEntries}
                        key={`reportEntry-${groupType}`}
                      >
                        <Text style={styles.reportKey}>
                          {`${groupType}: `}
                        </Text>
                        <Text style={styles.reportValue}>
                          {transformNumber(totalIncome)}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              );
            }

            if (Array.isArray(entry) && isReturnRate(entry)) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('returnRateOverYear')}
                  </Text>
                  {entry.map(({ returnRate }) => {
                    return (
                      <View
                        style={styles.reportEntries}
                        key={`reportEntry-${returnRate}`}
                      >
                        <Text style={styles.reportKey}>
                          {returnRate}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              );
            }

            if (
              Array.isArray(entry) &&
              isFavActivities(entry, entryName)
            ) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('favouriteActivities')}
                  </Text>
                  <View style={styles.reportHeaderGroup}>
                    <Text style={styles.reportHeaders}>
                      Activity name
                    </Text>
                    <Text style={styles.reportHeaders}>
                      Total votes
                    </Text>
                  </View>
                  {entry.map(({ activityName, totalVotes }) => {
                    return (
                      <View
                        style={styles.reportEntries}
                        key={`reportEntry-${activityName}`}
                      >
                        <Text style={styles.reportKey}>
                          {`${activityName}:`}
                        </Text>
                        <Text style={styles.reportKey}>
                          {totalVotes}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              );
            }

            if (isAvgOverall(entry)) {
              const { averageOverallExperienceScore } = entry;
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('avgOverall')}
                  </Text>
                  <View style={styles.reportEntries}>
                    <Text style={styles.reportKey}>
                      {transformNumber(averageOverallExperienceScore)}
                    </Text>
                  </View>
                </View>
              );
            }

            if (Array.isArray(entry) && isFavAspects(entry)) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('favouriteAspect')}
                  </Text>
                  {entry.map(({ favouriteAspects }) => {
                    return (
                      <View
                        style={styles.reportEntries}
                        key={`reportEntry-${favouriteAspects}`}
                      >
                        <Text style={styles.reportKey}>
                          {favouriteAspects}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              );
            }

            if (
              Array.isArray(entry) &&
              isActivitySuggestions(entry)
            ) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('suggestedActivities')}
                  </Text>
                  <View style={styles.reportHeaderGroup}>
                    <Text style={styles.reportHeaders}>
                      Suggested activity
                    </Text>
                    <Text style={styles.reportHeaders}>Date</Text>
                  </View>
                  {entry.map(({ suggestedNewActivity, date }) => {
                    return (
                      <View
                        style={styles.reportEntries}
                        key={`reportEntry-${suggestedNewActivity}`}
                      >
                        <Text style={styles.reportKey}>
                          {suggestedNewActivity}
                        </Text>
                        <Text style={styles.reportKey}>{date}</Text>
                      </View>
                    );
                  })}
                </View>
              );
            }

            if (
              Array.isArray(entry) &&
              isImprovementSuggestions(entry)
            ) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('improvementSuggestions')}
                  </Text>
                  <View style={styles.reportHeaderGroup}>
                    <Text style={styles.reportHeaders}>
                      Improvements
                    </Text>
                    <Text style={styles.reportHeaders}>
                      Overall experience score
                    </Text>
                  </View>
                  {entry.map(
                    ({ improvements, overallExperienceScore }) => {
                      return (
                        <View
                          style={styles.reportEntries}
                          key={`reportEntry-${improvements}`}
                        >
                          <Text style={styles.reportKey}>
                            {improvements}
                          </Text>
                          <Text style={styles.reportKey}>
                            {overallExperienceScore}
                          </Text>
                        </View>
                      );
                    },
                  )}
                </View>
              );
            }

            if (Array.isArray(entry) && isActivityReturns(entry)) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('activityReturns')}
                  </Text>
                  <View style={styles.reportHeaderGroup}>
                    <Text style={styles.reportHeaders}>
                      Improvements
                    </Text>
                    <Text style={styles.reportHeaders}>
                      Overall experience score
                    </Text>
                  </View>
                  {entry.map(({ activityName, totalReturn }) => {
                    return (
                      <View
                        style={styles.reportEntries}
                        key={`reportEntry-${activityName}`}
                      >
                        <Text style={styles.reportKey}>
                          {`${activityName}: `}
                        </Text>
                        <Text style={styles.reportKey}>
                          {totalReturn}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              );
            }

            if (Array.isArray(entry) && isSimilarExperience(entry)) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('similarExperience')}
                  </Text>
                  {entry.map(({ similarExperience }, index) => {
                    return (
                      <View
                        style={styles.reportEntries}
                        key={`reportEntry-${similarExperience}${index}`}
                      >
                        <Text style={styles.reportKey}>
                          {similarExperience}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              );
            }

            if (Array.isArray(entry) && isBookingPopularity(entry)) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('activitiesPopularity')}
                  </Text>
                  <View style={styles.reportHeaderGroup}>
                    <Text style={styles.reportHeaders}>
                      Activity name
                    </Text>
                    <Text style={styles.reportHeaders}>
                      Booking count
                    </Text>
                  </View>
                  {entry.map(({ activityName, count }) => {
                    return (
                      <View
                        style={styles.reportEntries}
                        key={`reportEntry-${activityName}`}
                      >
                        <Text style={styles.reportKey}>
                          {`${activityName}: `}
                        </Text>
                        <Text style={styles.reportKey}>{count}</Text>
                      </View>
                    );
                  })}
                </View>
              );
            }

            if (Array.isArray(entry) && isMotivation(entry)) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('userMotivation')}
                  </Text>
                  <View style={styles.reportHeaderGroup}>
                    <Text style={styles.reportHeaders}>
                      Booking motivation
                    </Text>
                    <Text style={styles.reportHeaders}>
                      Total votes
                    </Text>
                  </View>
                  {entry.map(({ name, count }) => {
                    return (
                      <View
                        style={styles.reportEntries}
                        key={`reportEntry-${name}`}
                      >
                        <Text style={styles.reportKey}>
                          {`${name}: `}
                        </Text>
                        <Text style={styles.reportKey}>{count}</Text>
                      </View>
                    );
                  })}
                </View>
              );
            }

            if (Array.isArray(entry) && isValueRatio(entry)) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('goodValuePercentage')}
                  </Text>
                  <View style={styles.reportHeaderGroup}>
                    <Text style={styles.reportHeaders}>
                      Voted good value
                    </Text>
                    <Text style={styles.reportHeaders}>
                      Voted bad value
                    </Text>
                  </View>
                  {entry.map(({ goodValue, notGoodValue }) => {
                    return (
                      <View
                        style={styles.reportEntries}
                        key={`reportEntry-${goodValue}`}
                      >
                        <Text style={styles.reportKey}>
                          {`${goodValue}% `}
                        </Text>
                        <Text style={styles.reportKey}>
                          {`${notGoodValue}%`}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              );
            }

            if (Array.isArray(entry) && isVisitorLocations(entry)) {
              return (
                <View key={`${entryName}-ReportEntry`}>
                  <Text style={styles.reportTitle}>
                    {getApiHeader('visitorsByLocation')}
                  </Text>
                  <View style={styles.reportHeaderGroup}>
                    <Text style={styles.reportHeaders}>Location</Text>
                    <Text style={styles.reportHeaders}>
                      Number of visitors
                    </Text>
                  </View>
                  {entry.map(({ numberOfVisitors, location }) => {
                    return (
                      <View
                        style={styles.reportEntries}
                        key={`reportEntry-${location}`}
                      >
                        <Text style={styles.reportKey}>
                          {`${location}: `}
                        </Text>
                        <Text style={styles.reportKey}>
                          {`${numberOfVisitors}%`}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              );
            }

            return <Text>No data found</Text>;
          })}
        </View>
      </Page>
    </Document>
  );
};
