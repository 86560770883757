import { FavActivitiesResponse } from 'http/types/Responses/ExperienceResponses/experienceResponses';

const getMostFavourite = async (): Promise<FavActivitiesResponse> => {
  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/activity/favouriteActivityList`,
    { method: 'GET' },
  );

  return data.json();
};

export default getMostFavourite;
