import { FavActivitiesByAgeResponse } from 'http/types/Responses/ActivityResponses/ActivityResponses';

const getFavActivitiesByAge =
  async (): Promise<FavActivitiesByAgeResponse> => {
    const data = await fetch(
      `${process.env.REACT_APP_API_PREFIX}/activity/favouriteActivityListAgeGroup`,
      { method: 'GET' },
    );

    return data.json();
  };

export default getFavActivitiesByAge;
