import { apiCallMap } from './apiCallMap';
import {
  FormInputs,
  date,
  ImprovementBound,
} from 'components/Fields/FormInputs';
import { checkApiKey } from 'components/PdfComponent/PdfMapping/types/apiNameMap';
import { FilteredApiFunction } from './types/apiResponseTypes';

// Holds fields that trigger dynamic fields when checked
const ignoredFields = [
  'numberOfVisitors',
  'toggleSuggestedActivities',
  'toggleActivityReturns',
  'toggleImprovementSuggestions',
  'toggleVisitorLocation',
];

// Fields that require additional input to the apiFunction
const additionalInputFields = [
  'visitorTimeframe',
  'suggestedActivities',
  'activityReturns',
  'improvementSuggestions',
  'visitorsByLocation',
];

const checkIsApiObject = (
  input: any,
): input is FilteredApiFunction => {
  return !!input && 'apiName' in input && 'apiFunction' in input;
};

export const fetchReports = async (formData: FormInputs | null) => {
  if (!formData) {
    return null;
  }

  // Getting only the fields that were called
  const filteredFormData: Partial<FormInputs> = Object.fromEntries(
    Object.entries(formData).filter(
      ([, value]) => value && !ignoredFields.includes(value),
    ),
  );

  // get inputs from filtered form data
  const formInputdata = new Map<string, date | ImprovementBound>();

  Object.entries(filteredFormData).forEach(([key, value]) => {
    if (
      additionalInputFields.includes(key) &&
      typeof value !== 'boolean'
    ) {
      formInputdata.set(key, value);
    }
  });

  // Removes dynamic fields
  const apiCallQueue = Object.keys(filteredFormData).filter(
    (entry) => !ignoredFields.includes(entry),
  );

  // Gets the associated http function with each formKey
  const apiFunctions = apiCallQueue.map((apiName: string) => {
    if (checkApiKey(apiName) && !ignoredFields.includes(apiName)) {
      return { apiName: apiName, apiFunction: apiCallMap[apiName] };
    }
    return undefined;
  });

  // filters out apiCalls with possibly undefined apiFunctions
  const filteredApiFunctions = apiFunctions.filter(
    (apiFunction): apiFunction is FilteredApiFunction =>
      checkIsApiObject(apiFunction),
  );

  if (!filteredApiFunctions.length) {
    return null;
  }

  // Calls each function in array and stores each functions returned data
  const returnedValues = filteredApiFunctions.map(async (apiCall) => {
    if (additionalInputFields.includes(apiCall.apiName)) {
      const formInputValue = formInputdata.get(apiCall.apiName);
      const data = await apiCall.apiFunction(formInputValue);

      return { [`${apiCall.apiName}`]: data };
    }

    const data = await apiCall.apiFunction();

    return { [`${apiCall.apiName}`]: data };
  });

  // Return array of data after it is done loading
  const data = await Promise.all(returnedValues);

  return data;
};
