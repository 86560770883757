import ErrorPage from 'pages/ErrorPage/ErrorPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import { ReportPage } from 'pages/ReportPage/ReportPage';
import { createBrowserRouter } from 'react-router-dom';
import AdminPage from 'pages/AdminPage/AdminPage';
import RecordsPage from 'pages/RecordsPage/RecordsPage';

// File containing all available routes for the Recording app
export const router = createBrowserRouter(
  [
    {
      errorElement: <ErrorPage />,
      children: [
        {
          path: '/',
          element: <LoginPage />,
        },
        {
          path: 'reports',
          element: <ReportPage isCollapsed={false} />,
        },
        { path: 'records', element: <RecordsPage /> },
        { path: 'admin', element: <AdminPage /> },
      ],
    },
  ],
  { basename: '/' },
);
