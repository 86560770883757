import { ActivityReturnsResponse } from 'http/types/Responses/ActivityResponses/ActivityResponses';
import { date } from 'components/Fields/FormInputs';

const getActivityReturns = async (
  input: date,
): Promise<ActivityReturnsResponse> => {
  const queryString = `startDate=${input.startDate}&endDate=${input.endDate}`;

  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/activity/totalReturn?${queryString}`,
    { method: 'GET' },
  );

  return data.json();
};

export default getActivityReturns;
