import getStaffMembers from 'http/ApiCalls/Staff/getStaffMembers';
import { User } from 'hooks/useValidateUser';
import { UseQueryResult, useQuery } from 'react-query';

// React query hook for getting all staff members in database
export const useGetStaffMembers = (
  deletedUser: string,
  isRemovinguser: boolean,
): UseQueryResult<Array<User>> => {
  return useQuery({
    queryKey: ['getStaffMembers', deletedUser], // Call every time a deletedUser changes
    queryFn: async () => {
      const data = getStaffMembers();
      return data;
    },
    enabled: !isRemovinguser, // Only call endpoint if input user is not being removed
    retry: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
