import { UseQueryResult, useQuery } from 'react-query';
import {
  getAllExperiences,
  getAllActivities,
  getAllVisitors,
} from 'http/ApiCalls';
import { date } from 'components/Fields/FormInputs';
import {
  ActivityRatings,
  NormalisedExperiences,
  Visitors,
} from 'http/types/apiReturnTypes';

// Enum for Record state
export enum RECORDS {
  VISITORS = 'Visitors',
  ACTIVITIES = 'Activities',
  EXPERIENCE = 'Experiences',
}

// Object holding specific endpoints that are called based on Records enum
const apiMap = {
  [RECORDS.ACTIVITIES]: (date: date) => getAllActivities(date),
  [RECORDS.EXPERIENCE]: (date: date) => getAllExperiences(date),
  [RECORDS.VISITORS]: (date: date) => getAllVisitors(date),
};

export type RecordsValues =
  | Array<NormalisedExperiences>
  | Array<ActivityRatings>
  | Array<Visitors>;

// React query hook that dynamically calls endpoints based on
// user input from Records page
export const useGetRecords = (
  recordMode: RECORDS,
  dates: date,
): UseQueryResult<RecordsValues> => {
  return useQuery({
    queryFn: async () => {
      const apiFunction = apiMap[recordMode];

      // Call api and return data
      const data = await apiFunction(dates);

      return data;
    },
    queryKey: ['records', { recordMode, dates }], // Make new call every time dates or record mode changes
    // Only make call if dates are valid
    enabled:
      !!dates.startDate &&
      !!dates.endDate &&
      dates.endDate !== 'Invalid Date' &&
      dates.startDate !== 'Invalid Date',
  });
};
