// Interfaces separated by section for Form inputs.
// interfaces are booleans to signify they were selected or not
// Fields requiring additional data will have extra types

interface VisitorInputs {
  avgOpinionFirstTime: boolean;
  numberOfVisitors: boolean;
  visitorTimeframe: date;
  toggleVisitorLocation: boolean;
  visitorsByLocation: boolean;
  discoveriesSource: boolean;
  userMotivation: boolean;
  similarExperience: boolean;
  returnRateOverYear: boolean;
}

interface ActivitiesInputs {
  activitiesPopularity: boolean;
  leastFavourite: boolean;
  favouriteActivities: boolean;
  favActivitiesByAge: boolean;
}

interface ExperienceOptions {
  goodValuePercentage: boolean;
  visitorQuotes: boolean; // not done
  avgOverall: boolean;
  favouriteAspect: boolean;
  toggleSuggestedActivities: boolean;
  suggestedActivities: date;
  toggleImprovementSuggestions: boolean;
  improvementSuggestions: ImprovementBound;
}

interface FinancialOptions {
  profitFromGroups: boolean;
  toggleActivityReturns: boolean;
  activityReturns: boolean;
}

export interface FormInputs
  extends VisitorInputs,
    ActivitiesInputs,
    ExperienceOptions,
    FinancialOptions {}

// Date formats used in RecordsPage

export const displayFormat = 'DD/MM/YYYY';
export const dateFormat = 'YYYY-MM-DD';

// Interfaces for fields requiring additional input for dates
// and Improvement bounds
export interface date {
  startDate: string;
  endDate: string;
}

export interface ImprovementBound {
  lowerBound: number;
  upperBound: number;
}
