import { Button } from 'antd';
import styles from './reportOptions.module.css';
import { FC, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormInputs } from 'components/Fields/FormInputs';
import {
  ReportSectionName,
  ResetFunctionMapper,
} from './reportOptionsTypes';
import {
  resetVisitorSection,
  resetExperienceSection,
  resetActivitiesSection,
  resetFinancialSection,
} from 'utils/formActions/resetFunctions';

interface Props {
  sectionName: ReportSectionName;
  formQuestions: ReactNode;
}

// Generic Report Options component to be used for rendering
// each Report Option section in report generator
export const ReportOptions: FC<Props> = ({
  formQuestions,
  sectionName,
}) => {
  const { resetField } = useFormContext<FormInputs>();

  // Object map containing all reset functions, called depending
  // on input section name
  const resetFunctions: ResetFunctionMapper = {
    visitor: () => resetVisitorSection(resetField),
    experience: () => resetExperienceSection(resetField),
    activity: () => resetActivitiesSection(resetField),
    financial: () => resetFinancialSection(resetField),
  };

  return (
    <>
      <div className={styles.optionContent}>
        <div className={styles.checkboxOptions}>
          <p className={styles.includeText}>Include in report?</p>
          <div>{formQuestions}</div>
          <div className={styles.buttonHolder}>
            <Button
              data-test-id={`discardBtn-${sectionName}`}
              className={styles.clearButton}
              onClick={resetFunctions[sectionName]}
            >
              Clear all
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
