import { notification } from 'antd';
import { User } from 'hooks/useValidateUser';

const deleteStaff = async (
  username: string,
): Promise<User | null> => {
  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/staff/deleteStaffMember?username=${username}`,
    {
      method: 'DELETE',
    },
  );

  if (data.ok) {
    notification.success({
      message: 'Successfully deleted staff member',
    });
  }

  if (!data.ok) {
    notification.error({
      message: 'Failed to delete a staff member',
      description: 'There was an error with deleting a staff member',
    });
  }

  const outcome = await data.json();

  return outcome;
};

export default deleteStaff;
