import { User } from 'hooks/useValidateUser';

const getStaffMembers = async (): Promise<Array<User>> => {
  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/staff/getAllStaffs`,
    {
      method: 'GET',
    },
  );

  const outcome = await data.json();

  return outcome;
};

export default getStaffMembers;
