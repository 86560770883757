import { Login } from 'pages/LoginPage/LoginPage';
import authenticateStaff from 'http/ApiCalls/Staff/authenticateStaff';
import { UseQueryResult, useQuery } from 'react-query';
import { setUser } from 'stores/userSlice';
import { useAppDispatch } from 'stores/store';

export interface User {
  username: string;
  admin: boolean;
}

// React query hook for validating user details
// Calls api every time user credentials change
export const useValidateUser = (
  userCredentials: Login,
): UseQueryResult<User> => {
  const { username, password } = userCredentials;

  const dispatch = useAppDispatch();

  return useQuery({
    queryKey: ['userCredentials', userCredentials],
    queryFn: async () => {
      const data = await authenticateStaff(userCredentials);

      if (!!data) {
        // Updates user property in redux store
        dispatch(setUser(data));
        return data;
      }
      return null;
    },
    enabled: !!username && !!password, // Only makes call if username and password aren't empty null or undefined
    retry: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
