import ReactPDF from '@react-pdf/renderer';
import { ReactElement, createContext } from 'react';

// Type used by updateContext
type PDFInput = ReactElement<
  ReactPDF,
  string | React.JSXElementConstructor<any>
>;

// Context used for update function provider
export const updateContext = createContext(
  (newDocument: PDFInput): void => {},
);
