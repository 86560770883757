import { date, dateFormat } from 'components/Fields/FormInputs';
import dayjs from 'dayjs';

// Date functions that the date pickers in the Records page use

// To be used in the end date picker and preventing
// it from using date in the future or before the startDate
export const isBeforeStartOrFuture = (
  currentDate: Date,
  dateState: date,
) => {
  const today = new Date();
  const startDate = dayjs(dateState.startDate, dateFormat).toDate();

  return currentDate < startDate || currentDate > today;
};

// To be used in the startDate date picker. Prevents user from selecting
// dates after the end date if it is present
export const isAfterEndDate = (
  currentDate: Date,
  dateState: date,
) => {
  const endDate = dayjs(dateState.endDate, dateFormat).toDate();
  return currentDate > endDate;
};
