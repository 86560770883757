import { UseQueryResult, useQuery } from 'react-query';
import { notification } from 'antd';
import { FormInputs } from 'components/Fields/FormInputs';
import { AllReturnedReports } from 'http/types/apiReturnTypes';
import { fetchReports } from 'http/fetchReports';

// Hook that dynamically calls specific endpoints based
// on form Input
export const useFetchReports = (
  formData: FormInputs | null,
): UseQueryResult<AllReturnedReports> => {
  return useQuery({
    queryFn: () => fetchReports(formData),
    onSuccess: () => {
      // Display success message
      notification.success({
        message: 'Success',
        description: 'Report data fetched successfully',
      });
    },
    onError: (error) => {
      const errorMessage =
        (error as { message?: string }).message ||
        'There was an error fetching the report';

      // Display error message
      notification.error({
        message: 'Error',
        description: errorMessage,
      });
    },
    enabled: !!formData, // Only make call if user has inputted details into the form
    refetchOnWindowFocus: false,
    retry: false,
  });
};
