import { VisitorOptions } from 'components/ReportOptions/VisitorOptions/VistorOptions';
import { ExperienceOptions } from 'components/ReportOptions/ExperienceOptions/ExperienceOptions';
import { CollapseProps } from 'antd';
import { ReportOptions } from 'components/ReportOptions/ReportOptions';
import { ActivityOptions } from 'components/ReportOptions/ActivityOptions/ActivityOptions';
import { FinancialOptions } from 'components/ReportOptions/FinancialOptions/FinancialOptions';

// JS CSS that antd requires for each component
const panelStyle = {
  backgroundColor: 'white',
  fontWeight: 400,
  fontSize: '18px',
};

// Renders all Selectable options in report generator
// and is passed to antd Collapse
export const collapsibleItems: CollapseProps['items'] = [
  {
    label: 'Visitor reports',
    key: 1,
    children: (
      <ReportOptions
        sectionName="visitor"
        formQuestions={<VisitorOptions />}
      />
    ),
    style: panelStyle,
  },
  {
    label: 'Experience reports',
    key: 2,
    style: panelStyle,
    children: (
      <ReportOptions
        sectionName="experience"
        formQuestions={<ExperienceOptions />}
      />
    ),
  },
  {
    label: 'Activity reports',
    key: 3,
    style: panelStyle,
    children: (
      <ReportOptions
        sectionName="activity"
        formQuestions={<ActivityOptions />}
      />
    ),
  },
  {
    label: 'Financial reports',
    key: 4,
    style: panelStyle,
    children: (
      <ReportOptions
        sectionName="financial"
        formQuestions={<FinancialOptions />}
      />
    ),
  },
];

// By default all Collapse sections in report generator
// Are opened
export const defaultActiveKeys = ['1', '2', '3', '4'];
