import { UnNormalisedAllExperiencesResponse } from 'http/types/Responses/ExperienceResponses/experienceResponses';
import { date } from 'components/Fields/FormInputs';

const getAllExperiences = async (
  timeframe: date,
): Promise<UnNormalisedAllExperiencesResponse> => {
  const dateString = `startDate=${timeframe.startDate}&endDate=${timeframe.endDate}`;

  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/experience/getAllExperiencesNormalised?${dateString}`,
    { method: 'GET' },
  );

  return data.json();
};

export default getAllExperiences;
