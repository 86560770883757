import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { SyntheticEvent } from 'react';

// Hardcoded sidebar items for antd
export const sideBarItems: Array<MenuItemType> = [
  { key: 'visitorReports', label: 'Visitor Reports' },
  { key: 'experienceReports', label: 'Experience Reports' },
  { key: 'activityReports', label: 'Activity Reports' },
  { key: 'financialReports', label: 'Financial Reports' },
];

// For getting report sections in Report generator and
// Scrolling to them. Section is showed in the centre of the screen
export const getReportSection = (id: string) => {
  const reportSection = document.getElementById(id);

  if (reportSection) {
    reportSection.scrollIntoView({
      block: 'center',
      inline: 'center',
    });
  }
};

// I had to declare my own interface because ant doesnt export Menu info
export type SidebarSelectionEvent = {
  domEvent: SyntheticEvent;
  key: string;
  keyPath: Array<string>;
};
