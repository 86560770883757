import { Button } from 'antd';
import styles from './chart.module.css';
import { FC } from 'react';
import generatePDF, { Margin, Resolution } from 'react-to-pdf';

interface Props {
  modalOpenFunction: (setModalOpen: boolean) => void;
}

// Custom footer for antd modal. Appears when user is generating
// chart only reports
const ChartPDFFooter: FC<Props> = ({ modalOpenFunction }) => {
  const findPDFSection = () =>
    document.getElementById('reportContainer');

  // Function for generating a chart pdf. Resolution is normal
  // To prevent large file sizes
  const onGeneratePdf = () => {
    generatePDF(findPDFSection, {
      page: { margin: Margin.SMALL },
      resolution: Resolution.NORMAL,
      filename: 'Share village report',
    });
    modalOpenFunction(false);
  };

  return (
    <div data-test-id="chartPDFFooter" className={styles.footer}>
      {/* Call function to close modal if clicked*/}
      <Button onClick={() => modalOpenFunction(false)}>Cancel</Button>
      <Button type="primary" onClick={onGeneratePdf}>
        Download PDF
      </Button>
    </div>
  );
};

export default ChartPDFFooter;
