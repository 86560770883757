import {
  SidebarSelectionEvent,
  getReportSection,
} from 'components/SidebarItems/sidebarItems';
import { getReportKeyIndex } from 'components/SidebarItems/sidebarTypes';

// For handling options user selects in the side bar
export const handleSidebarSelect = (
  selectionEvent: SidebarSelectionEvent,
  activeKeys: Array<string>,
  setActiveKeys: (newActiveKeys: Array<string>) => void,
) => {
  const reportKeyIndex = getReportKeyIndex(selectionEvent.key);

  if (reportKeyIndex) {
    // If the report section is already open, we want to go there instantly
    // Otherwise, we want to wait a bit to let the scroll function catch up
    const timeout = activeKeys.includes(reportKeyIndex) ? 0 : 140;
    setActiveKeys([...activeKeys, reportKeyIndex]);

    setTimeout(() => getReportSection(selectionEvent.key), timeout);
  }
};

// For handling active collapse sections in the Report Generator
export const handleActiveKeys = (
  newActiveKeys: string | Array<string>,
  setActiveKeys: (newActiveKeys: Array<string>) => void,
) => {
  // eslint-disable-next-line no-lone-blocks
  {
    // newActiveKeys is always an array but apparently sometimes it can be a string
    // so this check is necessary
    if (Array.isArray(newActiveKeys)) {
      setActiveKeys(newActiveKeys);
    }
  }
};
