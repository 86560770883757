import { FC } from 'react';
import {
  Control,
  Controller,
  RegisterOptions,
  FormState,
} from 'react-hook-form';
import { Input } from 'antd';
import styles from './input.module.css';
import { Login } from 'pages/LoginPage/LoginPage';
import ErrorMessage from '../ErrorField/ErrorMessage';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

interface Props {
  name: keyof Login;
  control: Control<Login>;
  formState: FormState<Login>;
  fieldQuestion?: string;
  defaultValue?: string;
  placeHolder?: string;
  rules?: RegisterOptions;
}

// Input field component utilising antd to get text input from User.
// Identical to InputField except it hides inputted info to be
// used for password fields
const PasswordField: FC<Props> = ({
  name,
  fieldQuestion,
  defaultValue,
  rules = { required: true },
  control,
  formState,
  placeHolder,
}) => {
  // Dynamically get fieldError from formState
  const { errors } = formState;
  const inputError = errors[name]?.message?.toString() ?? '';

  const { Password } = Input;

  return (
    <>
      {fieldQuestion && (
        <div className={styles.inputLabels}>
          <h3 className={styles.questionTitle}>{fieldQuestion}</h3>
          {errors[name] && <ErrorMessage errorMessage={inputError} />}
        </div>
      )}
      {/* Uses Controller from react hook form to update formState*/}
      <Controller
        name={name}
        rules={rules ?? { required: 'Please enter a value' }}
        control={control}
        defaultValue={defaultValue || ''}
        render={({ field }) => (
          <Password
            placeholder={placeHolder}
            data-test-id={`${name}-Input`}
            autoComplete="password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            className={styles.inputStyle}
            {...field}
          />
        )}
      />
    </>
  );
};

export default PasswordField;
