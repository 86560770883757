import { StyleSheet } from '@react-pdf/renderer';
export const transformNumber = (value: number) => {
  return !Number.isInteger(value) ? value.toFixed(2) : value;
};

// JSCSS for TextPDF component
// Styles only work if they are javaScript
export const styles = StyleSheet.create({
  section: { color: 'white', textAlign: 'center', margin: 30 },
  body: { margin: '40px' },
  pageTitle: { fontSize: '35px', marginBottom: '15' },
  reportTitle: {
    fontSize: '21px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  reportHeaderGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'left',
    marginBottom: '5px',
  },
  reportHeaders: {
    marginTop: '0px',
    marginBottom: '0px',
    fontWeight: 'extrabold',
    fontSize: '16px',
    color: 'darkblue',
  },
  middleHeaders: {
    marginTop: '0px',
    marginBottom: '0px',
    fontWeight: 'extrabold',
    fontSize: '16px',
    color: 'darkblue',
    width: '30%',
  },
  reportEntries: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'left',
  },
  reportKey: {
    fontWeight: 'ultrabold',
    marginTop: '0px',
    fontSize: '14px',
    marginBottom: '0px',
  },
  middleText: {
    fontSize: '14px',
    marginTop: '0px',
    marginBottom: '0px',
    textAlign: 'left',
    width: '30%',
  },
  reportValue: {
    fontSize: '14px',
    marginTop: '0px',
    marginBottom: '0px',
  },
});
