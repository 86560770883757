import { BookingPopularityResponse } from 'http/types/Responses/ActivityResponses/ActivityResponses';

export const getBookingCount =
  async (): Promise<BookingPopularityResponse> => {
    const data = await fetch(
      `${process.env.REACT_APP_API_PREFIX}/activity/activityCount`,
      { method: 'GET' },
    );

    return data.json();
  };

export default getBookingCount;
