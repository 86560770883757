import { ImprovementSuggestionResponse } from 'http/types/Responses/ExperienceResponses/experienceResponses';
import { ImprovementBound } from 'components/Fields/FormInputs';

const getImprovementSuggestions = async (
  input: ImprovementBound,
): Promise<ImprovementSuggestionResponse> => {
  const queryString = `lowerBound=${input.lowerBound}&upperBound=${input.upperBound}`;

  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/experience/getRatingsAndImprovements?${queryString}`,
    { method: 'GET' },
  );

  return data.json();
};

export default getImprovementSuggestions;
