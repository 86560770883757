import { Login } from 'pages/LoginPage/LoginPage';
import addStaff from 'http/ApiCalls/Staff/addStaff';
import { useMutation } from 'react-query';

// interface for creating a user with admin flag
export interface UserCreation extends Login {
  admin: boolean;
}

// React query hook for making a new staff member
export const useAddStaff = (staffMember: UserCreation) => {
  return useMutation({
    mutationKey: ['addStaff', staffMember], // Call every time staff member changes
    mutationFn: async () => addStaff(staffMember),
    retry: false,
  });
};
