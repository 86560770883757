import { FC, useState } from 'react';
import { FormInputs } from '../FormInputs';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './bound.module.css';
import { Input } from 'antd';
import ErrorMessage from '../ErrorField/ErrorMessage';

interface Props {
  name: keyof FormInputs;
  fieldQuestion?: string;
  defaultValue?: string;
  placeHolder?: string;
}
const BoundsField: FC<Props> = ({ name }) => {
  // Local state for keeping track of bounds
  const [bounds, setBounds] = useState({
    lowerBound: 0,
    upperBound: 0,
  });

  // Destructure react hook form
  const { control, formState, setError } =
    useFormContext<FormInputs>();
  const { errors } = formState;

  const { lowerBound, upperBound } = bounds;

  const hasNoErrors = !errors[name];

  // Manually calculated error boundaries

  const isLowerHigher = lowerBound > upperBound && hasNoErrors;
  const isNonValidNumber =
    (Object.values(bounds).includes(NaN) ||
      Math.sign(bounds.lowerBound) === -1 ||
      Math.sign(bounds.upperBound) === -1) &&
    hasNoErrors;

  const isBothZero =
    lowerBound === 0 && lowerBound === 0 && hasNoErrors;

  const isUpperHigherThanTen = upperBound > 10 && hasNoErrors;

  // Manually setting errors based on above checks
  // Very annoying, figure out different way to do this
  if (isLowerHigher) {
    setError(name, {
      type: 'onChange',
      message: 'Lower bound must be lower than upper bound',
    });
  }

  if (isNonValidNumber) {
    setError(name, {
      type: 'onChange',
      message: 'No non-number or negative values allowed',
    });
  }

  if (isBothZero) {
    setError(name, {
      type: 'onChange',
      message: 'Lower and upper bounds cant both be 0',
    });
  }

  if (isUpperHigherThanTen) {
    setError(name, {
      type: 'onChange',
      message: 'Upper bound must be equal to or lower than 10',
    });
  }

  const hasError = errors[name];

  return (
    <Controller
      // Uses Controller from react hook form to update formState
      name="improvementSuggestions"
      control={control}
      render={({ field }) => (
        <div className={styles.boundContainer}>
          <p>Lower bound</p>
          <Input
            className={styles.boundInput}
            data-test-id={`${name}-Lower`}
            onChange={(input) => {
              const newLowerBound = {
                ...bounds,
                lowerBound: +input.target.value,
              };
              setBounds(newLowerBound);
              field.onChange(newLowerBound);
            }}
          />
          <p>Upper bound</p>
          <Input
            className={styles.boundInput}
            data-test-id={`${name}-Upper`}
            onChange={(input) => {
              const newUpperBound = {
                ...bounds,
                upperBound: +input.target.value,
              };
              setBounds(newUpperBound);
              field.onChange(newUpperBound);
            }}
          />
          {/* Renders error if present*/}
          {hasError && (
            <ErrorMessage
              errorMessage={
                hasError.message ??
                'Error with entering bounds values'
              }
            />
          )}
        </div>
      )}
    />
  );
};

export default BoundsField;
