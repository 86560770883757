import { SimilarExperienceResponse } from 'http/types/Responses/VisitorResponses/visitorResponses';

const getSimilarExperience =
  async (): Promise<SimilarExperienceResponse> => {
    const data = await fetch(
      `${process.env.REACT_APP_API_PREFIX}/visitor/getSimilarExperiences`,
      { method: 'GET' },
    );

    return data.json();
  };

export default getSimilarExperience;
