import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, Divider, Tooltip } from 'antd';
import { FC, ReactNode, useEffect } from 'react';
import sharedStyles from '../question.module.css';
import styles from './select.module.css';
import { FormInputs } from '../FormInputs';
import { UnorderedListOutlined } from '@ant-design/icons';

interface Props {
  name: keyof FormInputs;
  fieldQuestion: string;
  dynamicQuestion?: ReactNode;
  dynamicWatchValue?: keyof FormInputs;
  icon?: ReactNode;
}

export const SelectField: FC<Props> = ({
  name,
  fieldQuestion,
  dynamicQuestion,
  dynamicWatchValue,
  icon,
}) => {
  // Destructure react hook form
  const { control, watch, resetField } = useFormContext();

  // Monitor current value and dynamic values
  const currentValue = watch(name);
  const dynamicWatch = dynamicWatchValue ? watch(name) : null;

  // Showing tooltip message based on if an Icon was passed
  // Otherwise render default text tooltip
  const tooltipMessage = icon
    ? 'This report is available with charts'
    : 'This is a text only report';

  // If an Icon was passed, render it, otherwise render default text icon
  const checkBoxIcon = icon ?? (
    <UnorderedListOutlined className={styles.icon} />
  );

  // For automatically resetting field value when it is no longer visbile
  // Checks if dynamic watch value is undefined and if its, resets the current field
  useEffect(() => {
    if (!dynamicWatch && dynamicWatchValue) {
      resetField(dynamicWatchValue);
    }
  }, [dynamicWatch, dynamicWatchValue, resetField]);

  return (
    <>
      {/* Uses Controller from react hook form to update formState*/}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <div className={styles.checkBoxContainer}>
              <div className={styles.questionContainer}>
                <Tooltip title={tooltipMessage}>
                  {checkBoxIcon}
                </Tooltip>
                <p className={sharedStyles.questionTitle}>
                  {fieldQuestion}
                </p>
              </div>
              <div className={styles.checkBox}>
                <Checkbox
                  {...field}
                  data-test-id={`${name}-checkBox`}
                  checked={currentValue}
                />
              </div>
            </div>
            {dynamicWatch && <>{dynamicQuestion}</>}
          </>
        )}
      />
      <Divider className={styles.dividerStyle} />
    </>
  );
};
