import { AvgOverallExperienceResponse } from 'http/types/Responses/ExperienceResponses/experienceResponses';

const getAvgOverallExperience =
  async (): Promise<AvgOverallExperienceResponse> => {
    const data = await fetch(
      `${process.env.REACT_APP_API_PREFIX}/experience/getAverageOverallExperienceScore`,
      { method: 'GET' },
    );

    return data.json();
  };

export default getAvgOverallExperience;
