// Manually set colour pallete for charts
const colours = {
  mimosa: '#A582FF',
  fuchsia: '#7547bf',
  electricPurple: '#a41cff',
  viollet: '#24075e',
  chestnut: '#F0D662',
  yellow: '#FFA700',
  muddyBrown: '#807100',
  citrus: '#94d900',
  green: '#0f871b',
  forestGreen: '#133308',
  aqua: '#00d9aa',
  jordyBlue: '#8AB9F1',
  cerulean: '#009fd9',
  blueBerry: '#002fd9',
  red: '#ff0000',
  salmon: '#FF7575',
  orange: '#d94100',
};

// Helper function for getting random numbers
function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export const getColour = (index: number): string => {
  // If number index fits within the length of the colour pallete,
  // get coulour from colour pallete
  if (index <= Object.values(colours).length - 1) {
    return Object.values(colours)[index];
  }

  // Otherwise return random colour via 3 random rgb values
  const randomRedHue = getRandomInt(255);
  const randomGreenHue = getRandomInt(255);
  const randomBlueHue = getRandomInt(255);

  const randomRGB = `rgb(${randomRedHue}, ${randomGreenHue}, ${randomBlueHue})`;

  return randomRGB;
};
