import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'stores/store';
import { router } from './routes';

// Base app component that renders all components and provides
// Providers to all components that use them
function App() {
  const queryClient = new QueryClient();

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <RouterProvider
            router={router}
            future={{ v7_startTransition: true }}
          />
        </Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
