import { FC } from 'react';
import { Typography } from 'antd';

interface ErrorTextProps {
  errorMessage: string;
}

// Error component that renders error message from provided input
// Via typography from antd
const ErrorMessage: FC<ErrorTextProps> = ({ errorMessage }) => (
  <Typography.Text
    type="danger"
    style={{
      fontWeight: 'bold',
      marginTop: '12px',
      width: '100%',
    }}
  >
    {errorMessage}
  </Typography.Text>
);

export default ErrorMessage;
