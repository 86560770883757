import {
  FormInputs,
  ImprovementBound,
  date,
} from 'components/Fields/FormInputs';

// Field names of text only fields
const textOnlyFields = [
  'numberOfVisitors',
  'toggleVisitorLocation',
  'similarExperience',
  'returnRateOverYear',
  'avgOverall',
  'favouriteAspect',
  'toggleSuggestedActivities',
  'toggleImprovementSuggestions',
];

export const containsTextOnly = (input: Partial<FormInputs>) => {
  // Removing all fields that were not selected
  const entries = Object.entries(input)
    .map(([key, value]) => {
      if (!!value) {
        return [key, value];
      }
      return undefined;
    })
    .filter(
      // Removing undefined from array of entries
      (
        element,
      ): element is (string | true | date | ImprovementBound)[] =>
        !!element,
    );

  // Turn selected fields into an object
  const selectedEntries = Object.fromEntries(entries);

  // Check if text only fields include keys from selected entries object
  const containsFields = Object.keys(selectedEntries).map((entry) =>
    textOnlyFields.includes(entry),
  );

  return containsFields.includes(true);
};
