import { Button, Select, Skeleton } from 'antd';
import { useState, useEffect } from 'react';
import CompanyHeader from 'components/CompanyHeader/CompanyHeader';
import { Navigate } from 'react-router-dom';
import { useGetStaffMembers } from 'hooks/adminHooks/useGetAllStaff';
import { useAddStaff } from 'hooks/adminHooks/useCreateStaff';
import { UserCreation } from 'hooks/adminHooks/useCreateStaff';
import { Input } from 'antd';
import styles from './admin.module.css';
import { useRemoveStaff } from 'hooks/adminHooks/useRemoveStaff';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  ReloadOutlined,
} from '@ant-design/icons';
import ActivityCreator from 'components/ActivityCreator/ActivityCreator';
import { useAppSelector } from 'stores/store';

const AdminPage = () => {
  // Local state for recording deletedUsers and credentials for new Staff members
  const [userCredentials, setUserCredentials] =
    useState<UserCreation>({
      username: '',
      password: '',
      admin: false,
    });
  const [deleteUser, setDeleteUser] = useState('');

  // Destructure values from react query hooks
  const { error, isLoading } = useRemoveStaff(deleteUser);
  const {
    data,
    isLoading: isLoadingStaff,
    refetch,
  } = useGetStaffMembers(deleteUser, isLoading);
  const {
    isLoading: isAddStaffLoading,
    data: newStaff,
    mutate,
  } = useAddStaff(userCredentials);

  // Get user from user store
  const user = useAppSelector((state) => state.userReducer.user);

  useEffect(() => {
    if (!isAddStaffLoading && newStaff) {
      // Reset staff creation fields
      setUserCredentials({
        username: '',
        password: '',
        admin: false,
      });

      // If new staff has been added, refetch to show new staff
      refetch();
    }
  }, [isAddStaffLoading, newStaff, refetch]);

  // Redirect to login page if user is not logged in
  if (!!!user || !user.admin) {
    return <Navigate to="/" replace={true} />;
  }

  // On delete function for delete button being called
  const handleDeleteUser = (username: string) => {
    setDeleteUser(username);
    if (error) {
      // Reset if delete user call fails
      setDeleteUser('');
    }
  };

  // Renders delete button beside each staff member's name.
  // Hides delete button if username is the same as current user,
  // To prevent user from deleting themself
  const showDeleteButton = (username: string) => {
    if (user.username !== username) {
      return (
        <Button
          danger
          data-test-id={`deleteStaff-${username}`}
          type="primary"
          onClick={() => handleDeleteUser(username)}
        >
          Delete
        </Button>
      );
    }
    return null;
  };

  // Password destructured from Input, used for
  // over the shoulder security
  const { Password } = Input;

  return (
    <>
      <CompanyHeader isLoginPage={false} />
      {user && (
        <div className={styles.adminPage}>
          <div className={styles.adminPanel}>
            <div className={styles.adminTypography}>
              <h1>Admin Panel</h1>
              <div className={styles.createStaffContainer}>
                <h3>Add staff</h3>
                <p>Username</p>
                <Input
                  value={userCredentials.username}
                  data-test-id="createStaffUsername"
                  placeholder="Enter a username"
                  onChange={(input) =>
                    setUserCredentials({
                      ...userCredentials,
                      username: input.target.value,
                    })
                  }
                />
                <p>Password</p>
                <Password
                  value={userCredentials.password}
                  placeholder="Enter a password"
                  data-test-id="createStaffPassword"
                  iconRender={(visible) =>
                    visible ? (
                      <EyeTwoTone />
                    ) : (
                      <EyeInvisibleOutlined />
                    )
                  }
                  onChange={(input) =>
                    setUserCredentials({
                      ...userCredentials,
                      password: input.target.value,
                    })
                  }
                />
                <p>Role</p>
                <Select
                  className={styles.adminSelect}
                  defaultValue={false}
                  data-test-id="createStaffRole"
                  value={userCredentials.admin}
                  onChange={(input) =>
                    setUserCredentials({
                      ...userCredentials,
                      admin: input,
                    })
                  }
                  options={[
                    { label: 'Admin', value: true },
                    { label: 'Non-Admin', value: false },
                  ]}
                />
                <Button
                  className={styles.staffSubmit}
                  loading={isAddStaffLoading}
                  disabled={
                    !!!userCredentials.username ||
                    !!!userCredentials.password
                  }
                  type="primary"
                  onClick={() => {
                    mutate();
                  }}
                >
                  Create staff member
                </Button>
              </div>
              <ActivityCreator />
              <div>
                <div className={styles.staffHeaders}>
                  {['Username', 'Admin'].map((header) => (
                    <p key={`staffHeader-${header}`}>{header}</p>
                  ))}
                  <Button
                    icon={<ReloadOutlined />}
                    type="link"
                    loading={isLoadingStaff}
                    onClick={() => {
                      refetch();
                    }}
                  >
                    Refresh
                  </Button>
                </div>
                {/* Return skeleton if api to call staff is loading*/}
                {(!data || !data.length || isLoadingStaff) && (
                  <Skeleton data-test-id="staffLoading" />
                )}
                {/* Dynamically render staff section based on data from endpoint*/}
                {!!data?.length && (
                  <div data-test-id="staffData">
                    {data.map(({ username, admin }) => (
                      <div
                        key={`staffEntry-${username}`}
                        className={styles.staffContainer}
                      >
                        <p>{`${username}`}</p>
                        <p>{`${admin}`}</p>
                        {showDeleteButton(username)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminPage;
