import { FavAspectResponse } from 'http/types/Responses/ExperienceResponses/experienceResponses';

const getFavouriteAspect = async (): Promise<FavAspectResponse> => {
  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/experience/getFavouriteAspect`,
    { method: 'GET' },
  );

  return data.json();
};

export default getFavouriteAspect;
