import { Controller, useFormContext } from 'react-hook-form';
import { FC } from 'react';
import { DatePicker } from 'antd';
import { FormInputs, date } from '../FormInputs';
import sharedStyles from '../question.module.css';
import styles from './range.module.css';
import ErrorMessage from '../ErrorField/ErrorMessage';

interface Props {
  name: keyof FormInputs;
  fieldQuestion: string;
  rules: Record<string, any>;
}

// Component utilising antd to handle dates. Shows dates
// in DD-MM-YYYY Format and shows field question

// To-do remove this and install dayjs
export const formatDateToString = (date: Date | null) => {
  if (date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based months
    const day = ('0' + date.getDate()).slice(-2); // Ensure two digits for day

    return `${year}-${month}-${day}`;
  } else {
    return '';
  }
};

export const isFutureDateDisabled = (currentDate: Date) => {
  const today = new Date();
  return currentDate > today; // Disable dates after today
};

const DatePickerRange: FC<Props> = ({
  name,
  fieldQuestion,
  rules,
}) => {
  // Destructure react hook form
  const { control, formState } = useFormContext<FormInputs>();
  const { errors } = formState;

  const { RangePicker } = DatePicker;

  // Dynamically gets error message from input name
  const dateRangeError = errors[name]?.message?.toString() ?? '';

  return (
    <>
      <p className={sharedStyles.dynamicQuestion}>{fieldQuestion}</p>
      {/* Uses Controller from react hook form to update formState*/}
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field }) => (
          <RangePicker
            className={styles.rangeFinder}
            format="DD/MM/YYYY"
            inputReadOnly
            onChange={(dates: any) => {
              // Gets date from on change and sets it as object to update FormInputs
              const formattedDates: date = {
                startDate:
                  dates && dates[0]
                    ? formatDateToString(dates[0].toDate())
                    : '',
                endDate:
                  dates && dates[1]
                    ? formatDateToString(dates[1].toDate())
                    : '',
              };
              field.onChange(formattedDates);
            }}
            disabledDate={(current) =>
              isFutureDateDisabled(current?.toDate() || new Date())
            }
          />
        )}
      />
      {/*Renders error if error is present*/}
      {errors[name] && <ErrorMessage errorMessage={dateRangeError} />}
    </>
  );
};

export default DatePickerRange;
