// Calculates how much room to leave for chart legend
export const getMarginOffset = (headers: Array<string>) => {
  if (headers.length < 5) {
    return headers.length * 30;
  }

  const headerLengths = headers.map((header) => header.length);

  const sumLength = headerLengths.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0,
  );

  const avgLength = sumLength / headerLengths.length;

  if (headers.length < 10) {
    return avgLength * headerLengths.length * 1.5;
  }

  // For very large data, up to 72
  if (headers.length >= 25) {
    return avgLength * headerLengths.length * 0.5;
  }

  // Normal length
  return avgLength * headerLengths.length;
};

export const getHeightOffset = (headers: Array<string>) => {
  if (headers.length <= 6) {
    return 40;
  }

  // For very large data, up to 72
  if (headers.length > 30) {
    return headers.length * 10;
  }

  return 0;
};
