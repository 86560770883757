import { BarChart } from '@mui/x-charts';
import styles from './bar.module.css';
import { FC } from 'react';
import { getColour } from '../colourPallete';
import { getHeightOffset, getMarginOffset } from './getMarginOffset';

interface Props {
  name: string;
  data: Array<number>;
  headers: Array<string>;
  title: string;
  ylabel: string;
}

const LegendBarChart: FC<Props> = ({
  headers,
  name,
  data,
  title,
  ylabel,
}) => {
  try {
    // Transform data into format MUI can use to render charts
    const mappedData = data.map((entry, index) => {
      return {
        data: [entry],
        label: `${headers[index]}`,
        id: `${index}-${entry}`,
        color: `${getColour(index)}`,
      };
    });

    // Calculate height and margin offset depending on the
    // input headers to ensure chart takes up half a page
    const marginOffset = getMarginOffset(headers);
    const heightOffset = getHeightOffset(headers);

    return (
      <div data-test-id={`reportChart-${name}`}>
        <BarChart
          className={styles.bar}
          margin={{ bottom: marginOffset }}
          width={770}
          height={480 + heightOffset}
          series={mappedData}
          // Display x and y label axis
          xAxis={[{ data: [title], scaleType: 'band' }]}
          yAxis={[{ label: ylabel }]}
          slotProps={{
            legend: {
              // Manually calculated formatting for chart legend
              direction: 'row',
              position: { vertical: 'bottom', horizontal: 'middle' },
              padding: 47,
              itemGap: 12,
              itemMarkHeight: 15,
              itemMarkWidth: 15,
            },
          }}
        />
      </div>
    );
  } catch {
    // Render error message if MUI encounters a problem
    return (
      <p>There was a problem with rendering data into a chart</p>
    );
  }
};

export default LegendBarChart;
