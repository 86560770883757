import { VisitorLocationResponse } from 'http/types/Responses/VisitorResponses/visitorResponses';
import { date } from 'components/Fields/FormInputs';

const getVisitorLocations = async (
  timeframe: date,
): Promise<VisitorLocationResponse> => {
  const dateString = `startDate=${timeframe.startDate}&endDate=${timeframe.endDate}`;

  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/visitor/getVisitorsPerLocation?${dateString}`,
    { method: 'GET' },
  );

  return data.json();
};

export default getVisitorLocations;
