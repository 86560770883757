import { PDFDownloadLink } from '@react-pdf/renderer';
import { AllReturnedReports } from 'http/types/apiReturnTypes';
import { TextPdf } from 'components/TextPDF/TextPDF';
import styles from './download.module.css';
import { FC } from 'react';

interface Props {
  data: AllReturnedReports | undefined;
}

// Wrapper for PDFDownloadLink component from react-pdf/renderer
// Styled to look like the antd primary button for consistency
const TextPDFDownloadLink: FC<Props> = ({ data }) => {
  return (
    <PDFDownloadLink
      className={styles.link}
      document={TextPdf(data ?? [])}
    >
      Download PDF
    </PDFDownloadLink>
  );
};

export default TextPDFDownloadLink;
