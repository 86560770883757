import { useState } from 'react';
import { Layout, Button } from 'antd';
import { passwordRules } from 'utils/formRules/formRules';
import { Navigate } from 'react-router-dom';
import styles from './login.module.css';
import CompanyHeader from 'components/CompanyHeader/CompanyHeader';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useValidateUser } from 'hooks/useValidateUser';
import InputField from 'components/Fields/InputField/InputField';
import ShareLogo from './ShareLogo.png';
import PasswordField from 'components/Fields/InputField/PasswordField';
import { useAppSelector } from 'stores/store';

export interface Login {
  username: string;
  password: string;
}

const LoginPage = () => {
  // Set react hook form to update every time input changes
  const { control, formState, handleSubmit } = useForm<Login>({
    mode: 'onChange',
  });
  const [credentials, setCredentials] = useState<Login>({
    username: '',
    password: '',
  });

  const { errors } = formState;

  // Destructure values from react query hook
  const { data, isLoading, isError, isFetched } =
    useValidateUser(credentials);

  // Get user from store
  const user = useAppSelector((state) => state.userReducer.user);

  // Change credential state to call useValidateUser hook
  const onSubmit: SubmitHandler<Login> = (data) => {
    setCredentials(data);
  };

  // If user is logged in, navigate to reports page
  if (user) {
    return <Navigate to="reports" replace={true} />;
  }

  return (
    <>
      <CompanyHeader isLoginPage={true} />
      <Layout>
        <form>
          <div className={styles.loginContainer}>
            <div className={styles.formImageContainer}>
              <img
                src={ShareLogo}
                alt={'Share Village Logo'}
                className={styles.formImage}
              />
            </div>
            <div className={styles.login}>
              <div className={styles.loginTextContainer}>
                <h1>Login</h1>
              </div>
              <InputField
                control={control}
                formState={formState}
                rules={{ required: 'Please input this field!' }}
                name="username"
                fieldQuestion="Username"
              />
              <PasswordField
                control={control}
                formState={formState}
                rules={passwordRules}
                name="password"
                fieldQuestion="Password"
              />
            </div>
            <div className={styles.outcomeContainer}>
              {(isError || (!data && isFetched)) &&
                !errors['password'] &&
                !errors['username'] && (
                  <p className={styles.loginFailed}>
                    Username or password are incorrect
                  </p>
                )}

              {user && (
                <p className={styles.loginSuccess}>
                  Login successful!
                </p>
              )}
            </div>
            <div className={styles.submit}>
              <Button
                className={styles.submitButton}
                type="primary"
                disabled={!formState.isValid}
                size="middle"
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default LoginPage;
