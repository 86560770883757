// Rules being used in React-hook form components
// Triggers the error state if user input doesn't meet
// the requirements

export const dateRules = {
  required: 'Please input this field!',
  pattern: {
    value: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/,
    message: 'Please enter a valid date!',
  },
};

export const requiredRules = {
  required: 'Please input this field!',
};

export const numberRules = {
  required: 'Please input a valid number!',
  min: 0,
  pattern: {
    value: /^\d+(\.\d+)?$/,
    message: 'Please enter a number!',
  },
};

export const passwordRules = {
  required: 'Please input this field!',
  pattern: {
    value: /^.*$/,
    message: 'Please enter a value',
  },
};
