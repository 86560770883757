import { date } from 'components/Fields/FormInputs';
import { AllActivityRatingsResponse } from 'http/types/Responses/ActivityResponses/ActivityResponses';

const getAllActivities = async (
  timeframe: date,
): Promise<AllActivityRatingsResponse> => {
  // backend doesn't support dates yet
  //const dateString = `startDate=${timeframe.startDate}&endDate=${timeframe.endDate}`;

  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/activity/getAllActivitiesWithRatings`,
    { method: 'GET' },
  );

  return data.json();
};

export default getAllActivities;
