import { FC, useEffect, useContext, useState } from 'react';
import { mapChartReport } from './PdfMapping/mapChartReport';
import styles from './pdf.module.css';
import { AllReturnedReports } from 'http/types/apiReturnTypes';
import { TextPdf } from '../TextPDF/TextPDF';
import { updateContext } from 'pages/ReportPage/reportContexts';
import { Link } from 'react-router-dom';
import { UsePDFInstance } from '@react-pdf/renderer';

interface Props {
  data: AllReturnedReports;
  pdfInstance: UsePDFInstance;
  useCharts: boolean;
}
// Function for opening tab in new window from input instance
const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const PdfComponent: FC<Props> = ({
  data,
  pdfInstance,
  useCharts,
}) => {
  // Local state for textPDF and controlling when redirect happens
  const [readyToRedirect, setReadyToRedirect] = useState(false);
  const [textPDF] = useState(TextPdf(data));

  const { url, loading } = pdfInstance;

  // Get update function from Provider
  const update = useContext(updateContext);

  // Transform data for chart reports
  const filteredData = data.filter((element) => element);
  const transformedData = Object.fromEntries(
    filteredData.map((data) => {
      const [reportKey] = Object.keys(data);
      const [reportValue] = Object.values(data);

      return [reportKey, reportValue];
    }),
  );

  // Calls update function if new data arrives from API
  // Set state with timeout to delay opening new tab
  useEffect(() => {
    update(textPDF);

    if (!useCharts) {
      setTimeout(() => setReadyToRedirect(true), 1000);
    }
  }, [data, textPDF, useCharts, update]);

  // Checks if ready to redirect, valid url is present
  // and pdf Instance is not loading. If checks pass, redirect
  useEffect(() => {
    if (!loading && url && readyToRedirect) {
      openInNewTab(url);
      setReadyToRedirect(false);
    }
  }, [loading, url, readyToRedirect]);

  return (
    <>
      {useCharts && (
        <div id="reportContainer" className={styles.pdf}>
          <h1>Share Village Report</h1>
          {mapChartReport(transformedData)}
          <br></br>
        </div>
      )}
      {/* Loading text for redirect*/}
      {!useCharts && url && (
        <div className={styles.linkHolder}>
          Your text report is being generated, please wait. If a new
          tab has not been opened click the link below.
          <Link to={url} target={'_blank'} rel="noopener noreferrer">
            Click here
          </Link>
          {/* If redirect doesn't work, a link is given to manually navigate*/}
        </div>
      )}
    </>
  );
};

export default PdfComponent;
