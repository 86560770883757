import { FC } from 'react';
import styles from './pie.module.css';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts';
interface Props {
  data: Array<{ id: number; value: number; label: string }>;
  name: string;
}

const LegendPieChart: FC<Props> = ({ data, name }) => {
  try {
    return (
      <div
        className={styles.pieChart}
        data-test-id={`reportChart-${name}`}
      >
        <PieChart
          margin={{ bottom: 90 }}
          series={[{ data, arcLabel: (item) => `${item.value}` }]}
          width={770}
          height={480}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontWeight: 'bold',
              fontSize: '35px',
            },
          }}
        />
      </div>
    );
  } catch {
    // Render error message if mui encounters a problem
    return (
      <p>There was a problem with rendering data into a chart</p>
    );
  }
};

export default LegendPieChart;
