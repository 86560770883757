import PdfComponent from 'components/PdfComponent/PdfComponent';
import { UsePDFInstance } from '@react-pdf/renderer';
import styles from './holder.module.css';
import { AllReturnedReports } from 'http/types/apiReturnTypes';
import { FC } from 'react';

interface Props {
  data: AllReturnedReports | null | undefined;
  pdfInstance: UsePDFInstance;
  useCharts: boolean;
}

// Component responsible for providing grey background for
// PDF Component in preview. Passes data to PDF Component
const PdfHolder: FC<Props> = ({
  data,
  pdfInstance: previewLink,
  useCharts,
}) => {
  return (
    <div className={useCharts ? styles.pdfHolder : ''}>
      {/* Hides grey background if current report is text only*/}
      {data && (
        <PdfComponent
          data={data}
          useCharts={useCharts}
          pdfInstance={previewLink}
        />
      )}
    </div>
  );
};

export default PdfHolder;
