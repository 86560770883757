import { AvgActivitiesScoreResponse } from 'http/types/Responses/VisitorResponses/visitorResponses';

const getAverageActivityScore =
  async (): Promise<AvgActivitiesScoreResponse> => {
    const data = await fetch(
      `${process.env.REACT_APP_API_PREFIX}/activity/getAverageActivityScore`,
      { method: 'GET' },
    );

    return data.json();
  };

export default getAverageActivityScore;
