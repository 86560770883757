import { notification } from 'antd';
import { User } from 'hooks/useValidateUser';

export interface CreatedUser extends User {
  password: string;
}

const addStaff = async (
  userCredentials: CreatedUser,
): Promise<User | null> => {
  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/staff/addStaff`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userCredentials),
    },
  );

  if (!data.ok) {
    notification.error({
      message: 'Failed to create a staff member',
      description:
        'Staff member may already exist or service unavailable',
    });
  }

  if (data.ok) {
    notification.success({
      message: 'Successfully created staff member',
    });
  }

  const outcome = await data.json();

  return outcome;
};

export default addStaff;
