import { SelectField } from 'components/Fields/SelectField/SelectField';
import styles from '../reportOptions.module.css';
import { BarChartOutlined } from '@ant-design/icons';

// Component holding all options for Activity Section
export const ActivityOptions = () => {
  return (
    <div id="activityReports" className={styles.options}>
      <SelectField
        name="activitiesPopularity"
        fieldQuestion="The popularity of activities based on booking amount"
        icon={<BarChartOutlined />}
      />
      <SelectField
        name="favouriteActivities"
        fieldQuestion="Most favourited activities in ascending order"
        icon={<BarChartOutlined />}
      />
      <SelectField
        name="leastFavourite"
        fieldQuestion="Least favourite activities in ascending order"
        icon={<BarChartOutlined />}
      />
      <SelectField
        name="favActivitiesByAge"
        fieldQuestion="Favourite activities by age group"
        icon={<BarChartOutlined />}
      />
    </div>
  );
};
