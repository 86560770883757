import { ApiMap } from './types/apiResponseTypes';
import { ImprovementBound, date } from 'components/Fields/FormInputs';
import {
  getLeastFavourite,
  getReturnRate,
  getIncomePerGroup,
  getMostFavourite,
  getFavActivitiesByAge,
  getVisitorsInTimeframe,
  getAverageActivityScore,
  getShareDiscover,
  getAvgOverallExperience,
  getFavouriteAspect,
  getSuggestedActivities,
  getImprovementSuggestions,
  getActivityReturns,
  getSimilarExperience,
  getBookingCount,
  getMotivations,
  getGoodValue,
  getVisitorLocations,
} from './ApiCalls';

// Object housing all apiCalls per form key
// Passes additional input if required
export const apiCallMap: Partial<ApiMap> = {
  leastFavourite: getLeastFavourite,
  favouriteActivities: getMostFavourite,
  discoveriesSource: getShareDiscover,
  avgOpinionFirstTime: getAverageActivityScore,
  favActivitiesByAge: getFavActivitiesByAge,
  profitFromGroups: getIncomePerGroup,
  returnRateOverYear: getReturnRate,
  visitorTimeframe: (input: date) => getVisitorsInTimeframe(input),
  avgOverall: getAvgOverallExperience,
  favouriteAspect: getFavouriteAspect,
  suggestedActivities: (input: date) => getSuggestedActivities(input),
  improvementSuggestions: (input: ImprovementBound) =>
    getImprovementSuggestions(input),
  activityReturns: (input: date) => getActivityReturns(input),
  similarExperience: getSimilarExperience,
  activitiesPopularity: getBookingCount,
  userMotivation: getMotivations,
  goodValuePercentage: getGoodValue,
  visitorsByLocation: (input: date) => getVisitorLocations(input),
};
