import { SelectField } from 'components/Fields/SelectField/SelectField';
import styles from '../reportOptions.module.css';
import DatePickerRange from 'components/Fields/DatePickerField/DatePickerRange';
import { dateRules } from 'utils/formRules/formRules';
import {
  BarChartOutlined,
  PieChartOutlined,
} from '@ant-design/icons';

// Component holding all fields for Financial section
export const FinancialOptions = () => {
  return (
    <div id="financialReports" className={styles.options}>
      <SelectField
        name="profitFromGroups"
        fieldQuestion="Profit from bookings by different groups"
        icon={<PieChartOutlined />}
      />
      <SelectField
        name="toggleActivityReturns"
        fieldQuestion="Total returns from each activity"
        icon={<BarChartOutlined />}
        dynamicQuestion={
          <DatePickerRange
            rules={dateRules}
            name="activityReturns"
            fieldQuestion="Select a timeframe"
          />
        }
        dynamicWatchValue="activityReturns"
      />
    </div>
  );
};
