import { useQuery } from 'react-query';
import createActivity from 'http/ApiCalls/Activity/createActivity';
import { ActivityState } from 'components/ActivityCreator/ActivityCreator';

// React query hook for calling endpoint to make an activity
export const useCreateActivity = (activity: ActivityState) => {
  return useQuery({
    queryKey: ['newActivity', activity], // Call endpoint every time activity changes
    queryFn: async () => createActivity(activity),
    // Only call endpoint if details match required minimum requirements
    enabled:
      !!activity.name &&
      activity.duration > 0 &&
      activity.price > 0 &&
      activity.capacity > 0,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
