import { Layout } from 'antd';

import styles from './error.module.css';

// Error page that user is navigated to if requested URL
// does not match paths of the components
const ErrorPage = () => {
  return (
    <>
      <div className={styles.companyHeader}>
        <h1 className={styles.dashboardTitle}>Reports Dashboard</h1>
      </div>
      <Layout>
        <div className={styles.errorText}>
          <h1>The requested resource does not exist</h1>
          <p>Please check you have the correct URL and try again.</p>
        </div>
      </Layout>
    </>
  );
};

export default ErrorPage;
