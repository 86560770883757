import { Select, DatePicker, Table } from 'antd';
import { useState, useEffect } from 'react';
import { RECORDS } from 'hooks/recordshooks/useGetRecords';
import {
  date,
  displayFormat,
  dateFormat,
} from 'components/Fields/FormInputs';
import CompanyHeader from 'components/CompanyHeader/CompanyHeader';
import { useGetRecords } from 'hooks/recordshooks/useGetRecords';
import { getColumnConfig } from './getColumnConfig';
import { transformRecords } from './transformData';
import {
  isBeforeStartOrFuture,
  isAfterEndDate,
} from 'utils/handleDates';
import styles from './records.module.css';
import dayjs from 'dayjs';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'stores/store';

const RecordsPage = () => {
  // Store values of current selection in DropDown and DatePickers
  const [dates, setDates] = useState<date>({
    startDate: '',
    endDate: '',
  });
  const [recordValue, setRecordValue] = useState<RECORDS>(
    RECORDS.VISITORS,
  );
  const [transform, setTransform] = useState([]);

  // Destructure values for getRecordsHook
  const { data, isLoading, isFetched } = useGetRecords(
    recordValue,
    dates,
  );

  // Get user from store
  const user = useAppSelector((state) => state.userReducer.user);

  useEffect(() => {
    // Update values every time new date is returned from hook
    if (data && !isLoading && isFetched) {
      const transformedData = transformRecords(data);
      setTransform(transformedData as any); // To DO: remove this any
    }
  }, [data, isLoading, isFetched]);

  const columns = getColumnConfig(data);
  try {
    // Navigate to LoginPage if user is not logged in
    if (!user) {
      return <Navigate to="/" replace={true} />;
    }

    if (user) {
      return (
        <>
          <CompanyHeader isLoginPage={false} />
          <h1 className={styles.currentTableHeader}>{recordValue}</h1>
          <div className={styles.recordOptionsGroup}>
            <div className={styles.recordOption}>
              <p>Records type</p>
              <Select
                className={styles.recordSelect}
                defaultValue={recordValue}
                onChange={(recordValue) =>
                  setRecordValue(recordValue)
                }
                options={[
                  { value: RECORDS.VISITORS, label: 'Visitors' },
                  { value: RECORDS.ACTIVITIES, label: 'Activities' },
                  { value: RECORDS.EXPERIENCE, label: 'Experience' },
                ]}
              />
            </div>
            <div className={styles.recordOption}>
              <p>Start date</p>
              <DatePicker
                data-test-id="startDatePicker"
                format={displayFormat}
                inputReadOnly
                onChange={(date) => {
                  const convertedDate =
                    dayjs(date).format(dateFormat) ?? '';
                  if (convertedDate === 'Invalid Date') {
                    setDates({
                      ...dates,
                      startDate: '',
                    });
                    return;
                  }
                  setDates({
                    ...dates,
                    startDate: convertedDate,
                  });
                }}
                disabledDate={(current) =>
                  isAfterEndDate(
                    current?.toDate() || new Date(),
                    dates,
                  )
                }
              />
            </div>

            <div className={styles.recordOption}>
              <p>End date</p>
              <DatePicker
                data-test-id="endDatePicker"
                inputReadOnly
                format={displayFormat}
                onChange={(date) => {
                  const convertedDate =
                    dayjs(date).format(dateFormat) ?? '';

                  if (convertedDate === 'Invalid Date') {
                    setDates({
                      ...dates,
                      endDate: '',
                    });
                    return;
                  }
                  setDates({
                    ...dates,
                    endDate: convertedDate,
                  });
                }}
                disabledDate={(current) =>
                  isBeforeStartOrFuture(
                    current?.toDate() || new Date(),
                    dates,
                  )
                }
              />
            </div>
          </div>
          <div className={styles.tableArea}>
            <Table
              className={styles.recordsTable}
              size="large"
              loading={isLoading}
              columns={columns}
              pagination={{ position: ['bottomCenter'] }}
              dataSource={transform} // TO-DO figure out how to remove this any
            />
          </div>
        </>
      );
    }
    return <></>;
  } catch {
    // Render error message if error occured
    return <div>Something went wrong</div>;
  }
};

export default RecordsPage;
