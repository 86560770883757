import { BookMotivationsResponse } from 'http/types/Responses/VisitorResponses/visitorResponses';

export const getMotivations =
  async (): Promise<BookMotivationsResponse> => {
    const data = await fetch(
      `${process.env.REACT_APP_API_PREFIX}/motivation/getMotivationCount`,
      { method: 'GET' },
    );

    return data.json();
  };

export default getMotivations;
