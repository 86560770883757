import { notification } from 'antd';
import { ActivityState } from 'components/ActivityCreator/ActivityCreator';
import { CreateActivityResponse } from 'http/types/Responses/ActivityResponses/ActivityResponses';

export const createActivity = async (
  activity: ActivityState,
): Promise<CreateActivityResponse> => {
  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/activity/addActivity`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(activity),
    },
  );

  if (!data.ok) {
    notification.error({
      message: 'Failed to create an activity',
    });
  }

  if (data.ok) {
    notification.success({
      message: 'Successfully created activity',
    });
  }

  return data.json();
};

export default createActivity;
