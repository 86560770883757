import { LeastFavResponse } from 'http/types/Responses/ExperienceResponses/experienceResponses';

const getLeastFavourite = async (): Promise<LeastFavResponse> => {
  const data = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/activity/leastFavouriteActivityList`,
    { method: 'GET' },
  );

  return data.json();
};

export default getLeastFavourite;
