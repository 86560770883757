import { BarChart } from '@mui/x-charts';
import styles from './labeldBar.module.css';
import { FC } from 'react';
import { getMarginOffset } from '../LegendBarChart/getMarginOffset';

interface Props {
  name: string;
  data: Array<number>;
  headers: Array<string>;
}

const LabeledBarChart: FC<Props> = ({ headers, data, name }) => {
  // Add space below chart to ensure chart takes up half a page
  const marginOffset = getMarginOffset(headers);

  try {
    return (
      <div
        className={styles.bar}
        data-test-id={`reportChart-${name}`}
      >
        <BarChart
          margin={{ bottom: marginOffset }}
          className={styles.bar}
          width={780}
          height={480}
          series={[{ data }]}
          xAxis={[{ data: headers, scaleType: 'band' }]}
          yAxis={[{ label: 'Visitor count' }]}
        />
      </div>
    );
  } catch {
    // Render error message if MUI encounters a problem
    return (
      <p>There was a problem with rendering data into a chart</p>
    );
  }
};

export default LabeledBarChart;
